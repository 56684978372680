import { useLocation, Link as RouterLink } from "react-router-dom";
import { MenuItem, useMediaQuery, Divider, Grid } from "@material-ui/core";
import { useMenuStyles } from "../styles/muiStyles";
import { useTheme } from "@material-ui/core/styles";
import PublicIcon from "@material-ui/icons/Public";
import RewardIcon from "@material-ui/icons/EmojiEventsOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PeopleIcon from "@material-ui/icons/People";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import StoreIcon from "@material-ui/icons/Store";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useAuthContext } from "../context/auth";
import { Logout } from "@mui/icons-material";
import { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { LOGOUT_USER } from "../graphql/mutations";
import { getErrorMsg } from "../utils/helperFuncs";
import { useStateContext } from "../context/state";

const DesktopNavMenu = () => {
  const { user, logoutUser } = useAuthContext();
  const client = useApolloClient();
  const [errorMsg, setErrorMsg] = useState(null);
  const { clearEdit, fcmToken } = useStateContext();
  const { pathname } = useLocation();
  const classes = useMenuStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [userLogout, { loading }] = useMutation(LOGOUT_USER, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });

  const handleLogout = () => {
    userLogout({
      variables: { id: user.id, fcmToken: fcmToken },
      update: (_, { data }) => {
        logoutUser();
        client.resetStore();
      },
    });
  };

  if (isMobile) return null;

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        <MenuItem
          selected={
            pathname === "/" ||
            (!pathname.startsWith("/tag") &&
              !pathname.startsWith("/reward") &&
              !pathname.startsWith("/leaderboard") &&
              !pathname.startsWith("/mint") &&
              !pathname.startsWith("/marketplace") &&
              !pathname.startsWith("/settings"))
          }
          component={RouterLink}
          to="/"
          className={classes.listItem}
        >
          <PublicIcon className={classes.menuIcon} />
          Feed
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/reward")}
          component={RouterLink}
          to={`/reward`}
          className={classes.listItem}
        >
          <RewardIcon className={classes.menuIcon} />
          Reward
        </MenuItem>

        <MenuItem
          selected={pathname.startsWith("/leaderboard")}
          component={RouterLink}
          to="/leaderboard"
          className={classes.listItem}
        >
          <PeopleIcon className={classes.menuIcon} />
          Leaderboard
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/mint")}
          component={RouterLink}
          to="/mint"
          className={classes.listItem}
        >
          <WalletIcon className={classes.menuIcon} />
          Mint
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/marketplace")}
          component={RouterLink}
          to="/marketplace"
          className={classes.listItem}
        >
          <StoreIcon className={classes.menuIcon} />
          Marketplace
        </MenuItem>
      </div>

      <div className={classes.bottomList}>
        <MenuItem
          selected={pathname.startsWith("/settings")}
          component={RouterLink}
          to="/settings"
          className={classes.listItem}
        >
          <SettingsOutlinedIcon className={classes.menuIcon} />
          Settings
        </MenuItem>

        <MenuItem onClick={handleLogout} className={classes.listItem}>
          <LogoutOutlinedIcon className={classes.menuIcon} />
          Signout
        </MenuItem>
      </div>
    </div>
  );
};

export default DesktopNavMenu;
