import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { VERIFY_USER, RESEND_OTP } from "../graphql/mutations";
import { useAuthContext } from "../context/auth";
import { useStateContext } from "../context/state";
import ErrorMessage from "./ErrorMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import logoImg from "../assets/logo.png";
import { getErrorMsg } from "../utils/helperFuncs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  Grid,
  Box,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";
import { useAuthFormStyles } from "../styles/muiStyles";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import onboard1 from "../svg/onboard-1.svg";
import onboard2 from "../svg/onboard-2.svg";
import onboard3 from "../svg/onboard-3.svg";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { useTheme } from "@material-ui/core/styles";

const validationSchema = yup.object({
  account: yup.string(),
  email: yup.string().required("Required"),
  otp: yup.string().required("Required"),
});

const VerifyForm = ({ setAuthType, closeModal }) => {
  const [showPass, setShowPass] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const classes = useAuthFormStyles();
  const { user } = useAuthContext();
  const { fcmToken } = useStateContext();
  const [email, setEmail] = useState("");
  const { setUser } = useAuthContext();
  const { notify } = useStateContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("875"));
  const checkHeight = useMediaQuery("(max-height:775px)");

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const { register, handleSubmit, reset, errors } = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const [userVerification, { loading }] = useMutation(VERIFY_USER, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });

  const [requestOTP, { loading: otp_loading }] = useMutation(RESEND_OTP, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });

  const onVerify = ({ email, otp }) => {
    userVerification({
      variables: { email, otp, fcmToken: fcmToken },
      update: (_, { data }) => {
        setUser(data.verifyUser);
        notify(`Welcome, ${data.verifyUser.username}! You're logged in.`);
        reset();
        closeModal();
      },
    });
  };

  const onResend = () => {
    requestOTP({
      variables: { email: email },
      update: (_, { data }) => {
        notify(`${data.resendOTP.message}`);
        reset();
      },
    });
  };

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    // Customize the CSS of the indicator dots here
    const dotColor = isSelected ? "#A6E3A7" : "#ffffff";
    const dotStyle = {
      backgroundColor: dotColor,
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 5px",
      display: "inline-block",
      cursor: "pointer",
    };

    return (
      <li
        style={dotStyle}
        onClick={onClickHandler}
        key={index}
        role="button"
        aria-label={label}
        tabIndex={0}
      />
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        backgroundColor: "rgba(28, 28, 28, 1)",
        height:
          checkHeight && isMobile ? "100%" : checkHeight ? undefined : "100%",
      }}
    >
      <Grid item xs={12} lg={6} sm={4}>
        <Box
          p={4}
          borderRadius={4}
          maxWidth="400px"
          mx="auto"
          textAlign="center"
        >
          <img src={logoImg} alt="Logo" />
          <Typography
            variant="h3"
            style={{ textAlign: "left", fontWeight: "bold" }}
          >
            Verify Your Email!
          </Typography>

          <Box mt={2} mb={1}>
            <form onSubmit={handleSubmit(onVerify)}>
              <div className={classes.inputField}>
                <TextField
                  required
                  fullWidth
                  inputRef={register}
                  name="email"
                  type="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  error={"email" in errors}
                  value={email}
                  onChange={handleEmailChange} // Update the state when the input changes
                  // helperText={
                  //   "username" in errors ? errors.username.message : ""
                  // }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.inputField}>
                <TextField
                  required
                  fullWidth
                  inputRef={register}
                  name="otp"
                  type={showPass ? "text" : "password"}
                  label="OTP"
                  variant="outlined"
                  size="small"
                  error={"password" in errors}
                  // helperText={
                  //   "password" in errors ? errors.password.message : ""
                  // }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPass((prevState) => !prevState)}
                          size="small"
                        >
                          {showPass ? (
                            <VisibilityOffIcon color="secondary" />
                          ) : (
                            <VisibilityIcon color="secondary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                startIcon={<ExitToAppIcon />}
                type="submit"
                disabled={loading}
                style={{
                  background:
                    "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
                }}
              >
                Verify
              </Button>
            </form>
            <div style={{ marginTop: "1em" }}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                disabled={otp_loading}
                style={{
                  background:
                    "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
                }}
                onClick={onResend}
              >
                Resend OTP
              </Button>
            </div>

            <Typography variant="body1" className={classes.footerText}>
              Already have an account?{" "}
              <Link
                onClick={() => setAuthType("login")}
                className={classes.link}
              >
                Login
              </Link>
            </Typography>
          </Box>
          <ErrorMessage
            errorMsg={errorMsg}
            clearErrorMsg={() => setErrorMsg(null)}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        className="hidden sm:block"
        style={{
          display: isMobile ? "none" : "",
          background: "#0D0D0D",
          height: "100%",
        }}
      >
        <Box p={4} position="relative" mt={8}>
          <Carousel
            autoPlay
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            swipeable={true}
            renderIndicator={renderIndicator}
            infiniteLoop
          >
            <div>
              <img
                alt=""
                src={onboard1}
                style={{ width: "350px", height: "350px" }}
              />
              <p className={classes.onboard}>Get Socialize</p>
              <p className={classes.desc}>
                Post like share content and connect to different people around
                the globe.
              </p>
            </div>
            <div>
              <img
                alt=""
                src={onboard2}
                style={{ width: "350px", height: "350px" }}
              />
              <p className={classes.onboard}>A new NFT experience</p>
              <p className={classes.desc}>
                Discover collect and sell extraordinary NFTs on the best
                marketplace.
              </p>
            </div>
            <div>
              <img
                alt=""
                src={onboard3}
                style={{ width: "350px", height: "350px" }}
              />
              <p className={classes.onboard}>Get Answers to Your Questions</p>
              <p className={classes.desc}>
                Have questions about cryptocurrency? PuchOOO is the perfect
                place to ask.
              </p>
            </div>
          </Carousel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VerifyForm;
