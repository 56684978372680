import { useState, useRef, useEffect } from "react";
import { useParams, useHistory, Link as RouterLink } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import * as dotenv from "dotenv";
import { GET_USER } from "../graphql/queries";
import { REGISTER_USER, REWARD_USER, MINT_NFT } from "../graphql/mutations";
import RecentQuestions from "../components/RecentQuestions";
import LoadingSpinner from "../components/LoadingSpinner";
import PersonIcon from "@material-ui/icons/Person";
import { useStateContext } from "../context/state";
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";
import { abi, IR_CONTRACT_ADDRESS } from "../constants";
import { ethers, Contract, providers, utils } from "ethers";
import {
  TextField,
  Avatar,
  Typography,
  Divider,
  Button,
  InputAdornment,
  Paper,
  FormLabel,
  Input,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useMintPageStyles } from "../styles/muiStyles";
import { useAuthContext } from "../context/auth";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import ConnectWallet from "../components/ConnectWallet";
import { pinFileToIPFS } from "../utils/pinata";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
} from "../utils/interact.js";
import { set } from "date-fns";

const MintPage = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const { address, isConnected } = useAccount();
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_ALCHEMY_API_URL
  );
  const wallet = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY, provider);
  const contract = new ethers.Contract(IR_CONTRACT_ADDRESS, abi, wallet);
  const classes = useMintPageStyles();
  const { notify } = useStateContext();
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [isConfirmed, setConfirmed] = useState(false);
  const [nftMint, { loading: nftMintLoading }] = useMutation(MINT_NFT, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });
  useEffect(() => {}, [url]);

  // handle file upload
  const handleFile = (e) => {
    setFile(e.target.files[0]);
    if (e.target.files.length !== 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const deleteImage = () => {
    setFile("");
    setImgSrc("");
    setURL("");
  };

  const uploadToIpfs = async () => {
    const imgHash = await pinFileToIPFS(file);
    setURL("https://gateway.pinata.cloud/ipfs/" + imgHash);
    notify("File uploaded to IPFS");
    notify("JSON data uploaded to IPFS");
    setConfirmed(true);
  };
  const onMintPressed = async () => {
    const { success, status } = await mintNFT(url, name, description);
    setStatus(status);
    if (success) {
      nftMint({
        variables: {
          collectionInput: {
            mintedBy: user.username,
            owner: address,
            title: name,
            description: description,
            imgLink: url,
          },
        },
        update: (_, { data }) => {
          setTimeout(function () {
            history.push(`/user/${user.username}`);
            notify("Collection added!");
          }, 2000);
        },
      });
      setName("");
      setDescription("");
      setURL("");
      setImgSrc("");
      setFile("");
    }
  };

  return (
    <>
      {user ? (
        <div className={classes.root}>
          {/* <div className={classes.userCard}> */}
          {/* <Avatar src={url} alt={name} className={classes.nft} /> */}
          {/* <Typography
              variant="h5"
              color="secondary"
              className={classes.cardText}
            >
              {isConnected && isConfirmed ? (
                <Button
                  style={{ marginTop: "1em" }}
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  // disabled={transaction}
                  onClick={onMintPressed}
                >
                  Mint
                </Button>
              ) : !isConfirmed ? (
                <Alert style={{ marginTop: "1em" }} severity="error">
                  Please confirm your image first
                </Alert>
              ) : (
                <ConnectWallet />
              )}
            </Typography> */}
          {/* </div> */}
          <div className={classes.infoCard}>
            <div className={classes.recentActivity}>
              <div className={classes.mintUserInfo}>
                <Avatar
                  alt={user.username}
                  src={`https://secure.gravatar.com/avatar/${user.id}?s=164&d=identicon`}
                  className={classes.postAvatar}
                />
                <Typography style={{ fontWeight: "bold" }} variant="heading1">
                  {user.username}
                </Typography>
              </div>
              <form
                fullWidth
                style={{
                  marginBottom: "1em",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "60%",
                  }}
                >
                  <div className={classes.customInputWrapper}>
                    <FormLabel htmlFor="body" style={{ color: "#FFFFFF" }}>
                      Photo/Video
                    </FormLabel>
                    <Input
                      id="image-upload"
                      type="file"
                      inputProps={{ accept: "image/*" }}
                      className={classes.customInput}
                      onChange={handleFile}
                      placeholder=" "
                    />
                    <div
                      className={classes.customLabel}
                      // onClick={handleClick}
                    >
                      Upload Photo/Video
                    </div>
                    <Typography variant="p" color="primary">
                      {file && <p>{file.name}</p>}
                    </Typography>
                  </div>

                  <div className={classes.inputWrapper}>
                    <FormLabel htmlFor="title" style={{ color: "#FFFFFF" }}>
                      Name
                    </FormLabel>
                    <TextField
                      required
                      fullWidth
                      value={description}
                      name="nftName"
                      placeholder="Enter name for NFT"
                      type="text"
                      variant="outlined"
                      size="small"
                      className={classes.inputField}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div>
                    <FormLabel
                      htmlFor="description"
                      style={{ color: "#FFFFFF" }}
                    >
                      Description
                    </FormLabel>
                    <div>
                      <TextField
                        required
                        multiline
                        rows={5}
                        fullWidth
                        value={description}
                        name="nftDescription"
                        placeholder="Let people know more about your work, creativity, and inspiration about this work"
                        type="text"
                        variant="outlined"
                        size="small"
                        className={classes.inputField}
                        onChange={(event) => setDescription(event.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <FormLabel
                      htmlFor="description"
                      style={{ color: "#FFFFFF" }}
                    >
                      Link
                    </FormLabel>

                    <div className={classes.inputField}>
                      <TextField
                        required
                        fullWidth
                        value={url}
                        name="nftLink"
                        placeholder="Enter link"
                        type="text"
                        variant="outlined"
                        size="small"
                        onChange={(event) => setURL(event.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "1em",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        style={{
                          marginTop: "1em",
                          background:
                            "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
                          color: "#FFFFFF",
                          width: "50%",
                        }}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={uploadToIpfs}
                      >
                        Mint
                      </Button>

                      <Typography variant="p" color="primary">
                        {file && <p>{file.name}</p>}
                      </Typography>
                    </div>
                  </div>

                  <div style={{ marginTop: "1em" }}>
                    <Typography variant="h6" color="primary">
                      {status}
                    </Typography>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <div>
            <Alert style={{ marginTop: "1em" }} severity="error">
              Please login to your account first
            </Alert>
          </div>
        </div>
      )}
    </>
  );
};

export default MintPage;
