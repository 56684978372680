import { Link as RouterLink } from "react-router-dom";
import { formatDayTime } from "../utils/helperFuncs";
import { Typography, Link, Avatar } from "@material-ui/core";
import { useQuesCardStyles } from "../styles/muiStyles";

const ByUser = ({
  username,
  userId,
  createdAt,
  updatedAt,
  filledVariant,
  isAnswer,
  formattedCreatedAt
}) => {
  const classes = useQuesCardStyles();

  return (
    <div
      className={filledVariant ? classes.filledByUser : classes.byUserWrapper}
    >
      <Avatar
        src={`https://secure.gravatar.com/avatar/${userId}?s=164&d=identicon`}
        alt={username}
        className={filledVariant ? classes.quesAnsAvatar : classes.homeAvatar}
        component={RouterLink}
        to={`/user/${username}`}
      />
      <div>
        <Link component={RouterLink} to={`/user/${username}`}>
          <Typography variant="body2">{username}</Typography>
        </Link>
        <Typography variant="caption" color="secondary">
          {filledVariant
            ? `${isAnswer ? "answered" : "asked"} ${formattedCreatedAt}}`
            : `${formatDayTime(createdAt)}`} {/* Use formatDayTime here */}
        </Typography>
        <br />

        {filledVariant && createdAt !== updatedAt && (
          <Typography variant="caption" color="secondary">
            {`updated ${formatDayTime(updatedAt)}`}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ByUser;
