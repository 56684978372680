import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../graphql/mutations";
import { getErrorMsg } from "../utils/helperFuncs";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { useAuthFormStyles } from "../styles/muiStyles";
import { Button, Chip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
const ConnectWallet = () => {
  const classes = useAuthFormStyles();
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState(null);
  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted;
        const connected = ready && account && chain;
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
            className={
              location.pathname === "/settings"
                ? classes.settingsBtnWrapper
                : ""
            }
          >
            {(() => {
              if (!connected) {
                return (
                  <div>
                    <Button
                      className={
                        location.pathname === "/settings"
                          ? classes.settingsBtn
                          : classes.button
                      }
                      variant="contained"
                      size="large"
                      fullWidth
                      startIcon={
                        location.pathname !== "/settings" && <WalletIcon />
                      }
                      disabled={loading}
                      onClick={openConnectModal}
                    >
                      Connect Wallet
                    </Button>
                    <Alert style={{ marginTop: "1em" }} severity="warning">
                      Wallet not connected!
                    </Alert>
                  </div>
                );
              }

              if (chain.unsupported) {
                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: 12,
                        justifyContent: "center",
                        marginTop: "1em",
                      }}
                    >
                      <Chip
                        label={"Switch Network"}
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.tag}
                        clickable
                        onClick={openChainModal}
                      />
                    </div>
                    <Alert style={{ marginTop: "1em" }} severity="error">
                      Wrong Network!
                    </Alert>
                  </div>
                );
              }

              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: 12,
                      justifyContent: "center",
                      marginTop: "1em",
                    }}
                  >
                    <Chip
                      label={"Switch Network"}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.tag}
                      clickable
                      onClick={openChainModal}
                    />
                    {/* <button
                          onClick={openChainModal}
                          style={{ display: 'flex', alignItems: 'center' }}
                          type="button"
                        >
                          {chain.hasIcon && (
                            <div
                              style={{
                                background: chain.iconBackground,
                                width: 12,
                                height: 12,
                                borderRadius: 999,
                                overflow: 'hidden',
                                marginRight: 4,
                              }}
                            >
                              {chain.iconUrl && (
                                <img
                                  alt={chain.name ?? 'Chain icon'}
                                  src={chain.iconUrl}
                                  style={{ width: 12, height: 12 }}
                                />
                              )}
                            </div>
                          )}
                          {chain.name}
                        </button> */}
                    <Chip
                      label={account.displayBalance}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.tag}
                    />
                    <Chip
                      label={"Disconnect"}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.tag}
                      clickable
                      onClick={openAccountModal}
                    />
                    {/* <Button 
                        color="secondary"
                        variant="contained"
                        onClick={openAccountModal} >
                          {account.displayBalance
                            ? ` (${account.displayBalance})`
                            : ''}
                        </Button> */}
                  </div>
                  <Alert style={{ marginTop: "1em" }} severity="success">
                    Wallet Connected!
                  </Alert>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectWallet;
