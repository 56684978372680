import { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { VIEW_QUESTION } from "../graphql/queries";
import { useStateContext } from "../context/state";
import { useAuthContext } from "../context/auth";
import QuesPageContent from "../components/QuesPageContent";
import RightSidePanel from "../components/RightSidePanel";
import AuthFormModal from "../components/AuthFormModal";
import LoadingSpinner from "../components/LoadingSpinner";
import PostedByUser from "../components/PostedByUser"
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";

import {
  Typography,
  Button,
  Divider,
  // Grid,
  // useMediaQuery,
} from "@material-ui/core";
import { Grid, useMediaQuery } from "@mui/material";
import { useQuesPageStyles } from "../styles/muiStyles";
import { useTheme } from "@material-ui/core/styles";

const QuestionPage = ({ modalOpen, setModalOpen, handleClickOpen }) => {
  const { clearEdit, notify } = useStateContext();
  const { user } = useAuthContext();
  const { quesId } = useParams();
  const [question, setQuestion] = useState(null);
  const classes = useQuesPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery(theme.breakpoints.down("900"));
  const [fetchQuestion, { data, loading }] = useLazyQuery(VIEW_QUESTION, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });

  useEffect(() => {
    fetchQuestion({ variables: { quesId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quesId]);

  useEffect(() => {
    if (data) {
      setQuestion(data.viewQuestion);
    }
  }, [data]);

  if (loading || !question) {
    return (
      <div style={{ minWidth: "100%", marginTop: "20%" }}>
        <LoadingSpinner size={80} />
      </div>
    );
  }

  const { author, title, views, createdAt, updatedAt } = question;

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        {/* <div className={classes.titleWrapper}>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            color="secondary"
            style={{ wordWrap: "anywhere" }}
          >
            {title}
          </Typography>
        </div> */}
        {/* <div>
          <PostedByUser
            username={author.username}
            userId={author.id}
            createdAt={createdAt}
          />
        </div> */}
        {/* <div className={classes.quesInfo}>
          <Typography variant="caption" style={{ marginRight: 10 }}>
            Asked <strong>{formatDateAgo(createdAt)} ago</strong>
          </Typography>
          {createdAt !== updatedAt && (
            <Typography variant="caption" style={{ marginRight: 10 }}>
              Edited <strong>{formatDateAgo(updatedAt)} ago</strong>
            </Typography>
          )}
          <Typography variant="caption">
            Viewed <strong>{views} times</strong>
          </Typography>
        </div> */}
      </div>

      <Divider />

      <Grid container className={classes.contentContainer}>
        <Grid item xl={9} lg={8.5} md={7}>
          <QuesPageContent
            question={question}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleClickOpen={handleClickOpen}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default QuestionPage;
