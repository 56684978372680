import { makeStyles } from "@material-ui/core/styles";

export const useBodyStyles = makeStyles(
  (theme) => ({
    root: {
      // width: "100vW",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      minHeight: "100vH",
      backgroundColor: "#111618",
    },
  }),
  { index: 1 }
);

export const useNavStyles = makeStyles(
  (theme) => ({
    leftPortion: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
    },
    navbarLogoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#111618",
      borderRight: "2px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient( #003638 100%, #72C974 100%)",
      [theme.breakpoints.down("xs")]: {
        border: "none",
      },
    },
    navbarMidContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#111618",
      borderBottom: "2px solid",
      borderRight: "2px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient( #003638 100%, #72C974 100%)",
      [theme.breakpoints.down("xs")]: {
        border: "none",
        flexWrap: "nowrap",
      },
    },
    navbarRightContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#111618",
      borderBottom: "2px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient( #003638 100%, #72C974 100%)",

      [theme.breakpoints.down("xs")]: {
        border: "none",
      },
    },
    navbarMid: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "15px",
    },
    navbarRight: {
      display: "flex",
      justifyContent: "center",
    },
    logoWrapper: {
      marginRight: "1em",
      display: "flex",
      justifyContent: "center",
      width: "14.3em",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        border: "none",
        background: "red",
        margin: "0",
      },
    },
    logo: {
      textTransform: "none",
      padding: "0.1em",
      marginLeft: "0.01em",
      borderRadius: 0,
      [theme.breakpoints.down("xs")]: {
        borderRight: 0,
        // background: "red",
        padding: 0,
      },
    },
    contentContainer: {
      display: "flex",
      alignItems: "center",
      maxWidth: "100%",
    },
    appBar: {
      background: "rgba(28, 28, 28, 1)",
      boxShadow: "none",
    },
    searchBar: {
      width: "80%",
      flexGrow: 0.2,

      [theme.breakpoints.down("sm")]: {
        marginLeft: "0.2em",
        marginRight: "0.2em",
      },

      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
        marginLeft: "0.6em",
        marginRight: "0.6em",
      },

      "& .MuiOutlinedInput-root": {
        position: "relative",
        zIndex: 1, // Ensure the pseudo-element is behind the input
      },

      "& .MuiOutlinedInput-root::before": {
        content: "''",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -1,
        border: "1px solid",
        borderRadius: "5px", // Apply border-radius to the pseudo-element
        borderImageSlice: 1,
        borderImageSource: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
      },

      "& .MuiOutlinedInput-input::placeholder": {
        color: "white",
      },
    },
    askQuesBtn: {
      color: "#ffffff",
      borderRadius: "10px",
      display: "flex",
      width: "60%",
      fontFamily: "Lexend",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      background: theme.palette.primary.own,

      [theme.breakpoints.down("900")]: {
        marginLeft: "2em",
        fontSize: "10px",
        backgroundColor: "red",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0 auto",
      },
    },
    searchBtn: {
      padding: "0em",
    },
    iconBtn: {
      [theme.breakpoints.down("xs")]: {
        padding: "0.2em",
      },
    },
  }),
  { index: 1 }
);

export const useMenuStyles = makeStyles(
  (theme) => ({
    menuIcon: {
      marginRight: "6px",
      fontSize: "1.3em",
    },
    svgImage: {
      objectFit: "contain",
      width: "20px",
    },
    menuItem: {
      [theme.breakpoints.down("1280")]: {
        padding: "6px 4px",
      },
      [theme.breakpoints.down("1200")]: {
        padding: "6px",
      },
    },
    closeIcon: {
      boxSizing: "border-box",
      border: "0.5px solid ",
      borderRadius: 3,
    },
    rootPanel: {
      position: "sticky",
      width: "100%",
      top: "5.5vH",
      minHeight: "94.5vh",
      display: "flex",
      "& .MuiMenuItem-root": {
        minHeight: "0",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "calc(100vh - 30em)",
    },
    list: {
      margin: "20px",
      display: "flex",
      flexDirection: "column", // Added property
      marginTop: "5em", // Moved Settings link to the bottom
      "& .Mui-selected": {
        fontWeight: "700",
        border: "none",
      },
      [theme.breakpoints.down("1200")]: {
        marginTop: "5em",
        margin: "0px",
      },
    },
    listItem: {
      [theme.breakpoints.down("1200")]: {
        fontSize: "14px",
      },
    },
    bottomList: {
      margin: "20px",
      display: "flex",
      flexDirection: "column", // Added property
      marginTop: "auto", // Moved Settings link to the bottom
      "& .Mui-selected": {
        fontWeight: "700",
        border: "none",
      },
      [theme.breakpoints.down("1200")]: {
        margin: "0px",
      },
    },
    madeByItem: {
      padding: "0.4em 0.8em 0.2em 0.8em",
    },
    userBtn: {
      textTransform: "none",
      display: "flex",
    },
    avatar: {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      marginRight: "0.4em",
      borderRadius: 2,
      [theme.breakpoints.down("900")]: {
        marginRight: 0,
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        width: theme.spacing(2.8),
        height: theme.spacing(2.8),
      },
    },
    moreBtn: {
      padding: 0,
    },
    userBtnMob: {
      padding: "0.3em",
      [theme.breakpoints.down("900")]: {
        padding: 0,
      },
    },
  }),
  { index: 1 }
);

export const useQuesListStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0.3em",
        paddingRight: "0.3em",
      },
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0.4em",
        paddingRight: "0.4em",
      },
    },
    btnGroupWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "1em 0",
      border: "none",

      "& .MuiButtonGroup-root": {
        borderBottom: "1.5px solid",
        borderImageSlice: 1,
        borderImageSource:
          "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      },

      "& .MuiButton-containedPrimary": {
        background:
          "-webkit-linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",

        fontSize: "16px",

        width: "130px",
      },
      "& .MuiButton-outlinedPrimary": {
        color: "#ffffff",
        border: "none",
        width: "130px",
        fontSize: "16px",
      },
    },
    loadBtnWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "50px",
    },
    loadBtn: {
      marginTop: "0.8em",
      marginBottom: "0.4em",
      width: "50%",
      display: "flex",
    },
    questListContainer: {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "15px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
        borderRadius: "10px",
      },
    },
    noQuesText: {
      textAlign: "center",
      marginTop: "2em",
    },
  }),
  { index: 1 }
);

export const useRightSidePanelStyles = makeStyles(
  (theme) => ({
    rootPanel: {
      position: "sticky",
      display: "flex",
      justifyContent: "center",
      background: "#111618",
      minHeight: "10vh",
      width: "100%",
      margin: "0 auto",
    },
    content: {
      width: "90%",
      marginTop: "2em",
    },
    tagsColumn: {
      paddingTop: "10px",
      padding: "0.8em",
      position: "relative",
      zIndex: 99,

      "&::before": {
        content: "''",
        position: "absolute",
        inset: 0,
        borderRadius: "18px",
        padding: "3px",
        background: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
        WebkitMask:
          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "clear",
        maskComposite: "clear",
        zIndex: -1,
      },

      // [theme.breakpoints.down("1010")]: {
      //   padding: "0.8em 0.2em",
      // },
    },
    topTagsHeading: {
      background: "-webkit-linear-gradient(110deg, #72C974  30%, #003638 70%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textAlign: "center",
      fontWeight: "bold",
    },
    tagsWrapper: {
      marginTop: "1em",
      padding: "10px",
      width: "100%",
      maxHeight: "300px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "15px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
        borderRadius: "10px",
      },
    },
    tagItem: {
      display: "flex",
      justifyContent: "space-around",
      padding: "10px 0px",
      borderBottom: "2px solid red",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
      fontSize: "13px",
      fontWeight: "bold",
    },
    tagCount: {
      fontSize: "13px",
      fontWeight: "bold",
      width: "50%",
      textAlign: "center",
    },
  }),
  { index: 1 }
);

export const useQuesCardStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: "5px",
      marginBottom: "1em",
      display: "flex",
      flexDirection: "column",
      padding: "0.5em 0",
      paddingRight: "1em",
      margin: "0 auto",
      width: "60%",
      background: "transparent",

      [theme.breakpoints.down("1280")]: {
        width: "80%",
      },
      [theme.breakpoints.down("900")]: {
        width: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    infoWrapper: {
      display: "flex",
    },
    voteWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      gap: "5px",
    },
    ansWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      gap: "5px",
    },
    mainText: {
      fontSize: "8px",
      fontWeight: 500,
      color: "#949494",
    },
    title: {
      fontSize: "1.2em",
      fontWeight: 500,
      wordWrap: "anywhere",
      textDecoration: "none",
      "&:hover": {
        filter: "brightness(200%)",
      },
      transition: "all 0.4s ease",
    },
    innerInfo: {
      display: "flex",
      flexDirection: "column",
    },
    quesDetails: {
      marginLeft: "35px",
      paddingLeft: "0.2em",
      paddingBottom: "0.7em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      borderBottom: "3px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
    },
    quesImg: {
      objectFit: "contain",
      maxWidth: "100%",
      height: "auto",
      maxHeight: "300px",
      width: "100%",
      cursor: "pointer",
    },
    tagsWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    tag: {
      marginRight: "0.5em",
      marginTop: "0.5em",
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
    },
    bottomWrapper: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
    },
    byUserWrapper: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.7em",
    },
    filledByUser: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.7em",
      backgroundColor: `${theme.palette.primary.main}10`,
      padding: "0.4em",
      borderRadius: 3,
      border: `1px solid ${theme.palette.primary.main}40`,
    },
    homeAvatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: "0.4em",
      borderRadius: "50%",
    },
    quesAnsAvatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginRight: "0.4em",
      borderRadius: "2px",
    },
  }),
  { index: 1 }
);

export const useDialogStyles = makeStyles(
  (theme) => ({
    dialogWrapper: {
      padding: 0,
      overflow: "hidden",
      [theme.breakpoints.down("400")]: {
        padding: "0 !important",
        margin: "0 !important",
        maxHeight: "100% !important",
        height: "100%",
        borderRadius: "0 !important",
      },
    },
    imgDialogWrapper: {
      width: "900px",
    },
    closeIconWrapper: {
      display: "flex",
      justifyContent: "end",
      paddingBottom: "10px",
    },
    quesImgView: {
      width: "100%",
      minHeight: "20px",
      maxHeight: "600px",
      objectFit: "contain",
    },
    menuIcon: {
      marginRight: "6px",
      fontSize: "1.3em",
    },
    upDownIcon: {
      color: "#aa9aaa",
      fontSize: 32,
    },
  }),
  { index: 1 }
);

export const useAuthFormStyles = makeStyles(
  (theme) => ({
    root: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      [theme.breakpoints.down("xs")]: {
        padding: "0 0 0 0",
      },
    },
    welcomeTxt: {
      textAlign: "left",
      fontWeight: "bold",
      [theme.breakpoints.down("450")]: {
        textAlign: "center",
        fontSize: "2.2rem",
      },
      [theme.breakpoints.down("325")]: {
        fontSize: "2rem",
      },
    },
    boxContainer: {
      padding: "0px",
      [theme.breakpoints.down("325")]: {
        padding: "32px 16px",
      },
    },
    inputField: {
      marginBottom: "1.5em",
    },
    submitButton: {
      marginTop: "1.8em",
    },
    titleLogo: {
      display: "block",
      width: "5em",
      margin: "0 auto 2em auto",
    },
    footerText: {
      marginTop: "1em",
      textAlign: "center",
      [theme.breakpoints.down("900")]: {
        fontSize: "14px",
      },
    },
    link: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    button: {
      background: theme.palette.primary.own,
      marginTop: "2rem",
      color: "#FFFFFF",
    },
    settingsBtnWrapper: {
      width: "50%",
    },
    settingsBtn: {
      background: theme.palette.primary.own,
      color: "#FFFFFF",
      width: "100%",
    },
    onboard: {
      width: "311px",
      height: "50px",
      margin: "0 auto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "50px",
      color: "#FFFFFF",
      // paddingBottom: '3em',
      marginTop: "1em",
    },
    desc: {
      width: "352px",
      height: "46px",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
      color: "#C1C1C1",
      margin: "0 auto",
      marginTop: "4em",
      marginBottom: "4em",
    },
  }),
  { index: 1 }
);

export const useRewardPageStyle = makeStyles(
  (theme) => ({
    root: {
      padding: "0.4em 0.7em",
      paddingBottom: "100px",
      backgroundColor: "#111618",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0.3em",
        paddingRight: "0.3em",
        border: "none",
      },
    },
    rewardWrapper: {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "15px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
        borderRadius: "10px",
      },
    },
    rewardsMainHead: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "48px",
      textAlign: "center",
      [theme.breakpoints.down("350")]: {
        fontSize: "25px",
      },
    },
    youLlGet: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",
      [theme.breakpoints.down("350")]: {
        fontSize: "17px",
      },
    },
    rewardsHead: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "38px",
      lineHeight: "2px",
      [theme.breakpoints.down("350")]: {
        fontSize: "32px",
      },
    },
    carouselBox: {
      background: "rgba(255, 255, 255, 0.1)",
      width: "290px",
      height: "362px",
      borderRadius: "10px",
      [theme.breakpoints.down("350")]: {
        width: "220px",
        height: "340px",
      },
    },
    carouselImages: {
      width: "250px",
      // height: "250px",
      objectFit: "contain",
      [theme.breakpoints.down("350")]: {
        width: "220px",
      },
    },
    onboard: {
      // width: "260px",
      width: "100%",
      height: "32px",
      margin: "0 auto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      color: "#FFFFFF",
      marginTop: "1em",
    },
    buttonReward: {
      background: "#FFBC0F",
      width: "76px",
      height: "42px",
      marginBottom: "6em",
      "&.Mui-disabled": {
        color: "#FFFFFF",
        background: "#000000",
      },
      [theme.breakpoints.down("350")]: {
        marginBottom: "4em",
      },
    },
    buttonReputation: {
      background: "#FFBC0F",
      width: "142px",
      height: "42px",
      marginBottom: "6em",

      "&.Mui-disabled": {
        color: "#FFFFFF",
      },
    },
    desc: {
      width: "352px",
      height: "46px",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "22px",
      color: "#C1C1C1",
      margin: "0 auto",
      marginTop: "4em",
      marginBottom: "4em",
    },
  }),
  { index: 1 }
);

export const useTagsPageStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: "1em",
      padding: "0.4em 0.7em",
      // width: "100%",
    },
    titleText: {
      marginBottom: "0.9em",
    },
    filterInput: {
      marginTop: "1.2em",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    tagsWrapper: {
      marginTop: "1em",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(157px, 1fr))",
      gridGap: "12px",
    },
    tagBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "0.8em",
      paddingBottom: "0.4em",
      border: "1px solid #d3d3d3",
      borderRadius: 4,
    },
    tag: {
      marginBottom: "0.9em",
    },
  }),
  { index: 1 }
);

export const useUsersPageStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: "1em",
      padding: "0.4em 0.7em",
      width: "100%",
    },
    filterInput: {
      marginTop: "1.2em",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    usersWrapper: {
      marginTop: "1.4em",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
      gridGap: "14px",
    },
    userBox: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginRight: "0.6em",
      borderRadius: 2,
    },
  }),
  { index: 1 }
);

export const useQuesPageStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      paddingLeft: "0.7em",
      marginTop: "1em",
      [theme.breakpoints.down("900")]: {
        padding: "0 0.7em",
      },
    },
    titleWrapper: {
      paddingBottom: "0.4em",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    contentContainer: {
      flexWrap: "nowrap !important",
      justifyContent: "space-around",
      gap: "5rem",
      [theme.breakpoints.down("1280")]: {
        gap: "3.5rem",
      },
      [theme.breakpoints.down("900")]: {
        justifyContent: "flex-start",
      },
    },
    quesInfo: {
      display: "flex",
      paddingBottom: "0.8em",
    },
    quesImg: {
      objectFit: "contain",
      maxWidth: "100%", // Ensures the image doesn't exceed its natural size
      height: "auto",
      maxHeight: "300px", // Limits the height to 300px
      width: "100%",
      cursor: "pointer",
    },
    content: {
      paddingTop: "0.5em",
      width: "100%",
      paddingBottom: "1em",
    },
    quesAnsWrapper: {
      display: "flex",
      marginBottom: "1em",
    },
    quesAnsInnerWrapper: {
      width: "100%",
    },
    rightIconsWrapper: {
      display: "flex",
    },
    voteColumn: {
      display: "flex",
      flexDirection: "column",
      width: 30,
      alignItems: "center",
    },
    quesBody: {
      margin: "0 2.5em",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0",
      },
    },
    tag: {
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
      marginRight: "0.5em",
    },
    tagsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "0.5em",
    },
    infoWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    likesDislikesWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
    },
    viewWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      gap: "5px",
    },
    voteWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      gap: "5px",
    },
    ansWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      gap: "5px",
    },
    linearDivider: {
      borderBottom: "2px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
    },
    mainText: {
      fontSize: "8px",
      fontWeight: 500,
      color: "#949494",
    },
    bottomWrapper: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginTop: "1.4em",
      marginBottom: "0.8em",
    },
    bottomBtns: {
      padding: "0.15em",
    },
    commentWrapper: {
      padding: "0.5em 0.2em",
    },
    commentField: {
      position: "relative",
      zIndex: 99,
      marginBottom: "1em",

      "&::before": {
        border: "none",
        content: "''",
        position: "absolute",
        inset: 0,
        borderRadius: "18px",
        padding: "2px",
        background: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
        WebkitMask:
          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "clear",
        maskComposite: "clear",
        zIndex: -1,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "transparent !important",
      },
    },
    commentBtns: {
      padding: "0 0.1em",
      minWidth: "3em",
    },
    smallForm: {
      marginTop: "1em",
    },
    submitCancelBtns: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "0.3em",
    },
    answersWrapper: {
      marginTop: "1em",
    },
    answerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0.5em",
      padding: "0 0.5em",
    },
    acceptIcon: {
      color: "#aa9aaa",
      fontSize: 32,
    },
    checkedAcceptIcon: {
      color: "#2e8b57",
      fontSize: 32,
    },
    answerWrapper: {
      marginBottom: "0.5em",
    },
    answerForm: {
      marginTop: "2em",
    },
    footerText: {
      marginTop: "1em",
      marginBottom: "1em",
      display: "flex",
      alignItems: "flex-start",
    },
    footerTag: {
      marginRight: "0.5em",
    },
  }),
  { index: 1 }
);

export const useAnsPageStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      paddingLeft: "0.7em",
      marginTop: "1em",
      [theme.breakpoints.down("900")]: {
        padding: "0 0.7em",
      },
    },
    userNameWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    titleWrapper: {
      paddingBottom: "0.4em",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },
    },
    contentContainer: {
      flexWrap: "nowrap !important",
      justifyContent: "space-around",
      gap: "5rem",
      [theme.breakpoints.down("1280")]: {
        gap: "3.5rem",
      },
      [theme.breakpoints.down("900")]: {
        justifyContent: "flex-start",
      },
    },
    quesInfo: {
      display: "flex",
      paddingBottom: "0.8em",
    },
    quesImg: {
      objectFit: "contain",
      maxWidth: "100%",
      height: "auto",
      maxHeight: "300px",
      width: "100%",
      cursor: "pointer",
    },
    content: {
      paddingTop: "0.5em",
      width: "100%",
      paddingBottom: "1em",
    },
    quesAnsWrapper: {
      display: "flex",
      marginBottom: "1em",
    },
    quesAnsInnerWrapper: {
      width: "100%",
    },
    rightIconsWrapper: {
      display: "flex",
    },
    voteColumn: {
      display: "flex",
      flexDirection: "column",
      width: 30,
      alignItems: "center",
    },
    quesBody: {
      margin: "0 2.5em",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0",
      },
    },
    tag: {
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
    },
    tagsWrapper: {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "0.5em",
    },
    infoWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    likesDislikesWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
    },
    viewWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      gap: "5px",
    },
    voteWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      gap: "5px",
    },
    ansWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100px",
      gap: "5px",
    },
    linearDivider: {
      borderBottom: "2px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
    },

    mainText: {
      fontSize: "8px",
      fontWeight: 500,
      color: "#949494",
    },
    bottomWrapper: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginTop: "1.4em",
      marginBottom: "0.8em",
    },
    bottomBtns: {
      padding: "0.15em",
    },
    commentWrapper: {
      padding: "0.5em 0.2em",
    },
    commentField: {
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
      marginBottom: "1em",
    },
    commentBtns: {
      padding: "0 0.1em",
      minWidth: "3em",
    },
    smallForm: {
      marginTop: "1em",
    },
    submitCancelBtns: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "0.3em",
    },
    answersWrapper: {
      marginTop: "1em",
    },
    answerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0.5em",
      padding: "0 0.5em",
    },
    acceptIcon: {
      color: "#aa9aaa",
      fontSize: 32,
    },
    checkedAcceptIcon: {
      color: "#2e8b57",
      fontSize: 32,
    },
    answerWrapper: {
      marginBottom: "0.5em",
    },
    answerForm: {
      marginTop: "2em",
    },
    footerText: {
      marginTop: "1em",
      marginBottom: "1em",
      display: "flex",
      alignItems: "flex-start",
    },
    footerTag: {
      marginRight: "0.5em",
    },
  }),
  { index: 1 }
);

export const useLeaderPageStyles = makeStyles(
  (theme) => ({
    leaderboardWrapper: {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "15px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
        borderRadius: "10px",
      },
    },
    container: {
      overflow: "auto",
      justifyContent: "center",
    },
    header: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    leaderMain: {
      justifyContent: "center",
      position: "sticky",
      top: 0,
      background: "#111618",
      [theme.breakpoints.down("xs")]: {
        position: "unset",
      },
    },
    leaderboardContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    leaderboardItemContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    leaderpositionItem: {
      background: "none",
      marginTop: "2em",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    leaderboardItem: {
      background: "rgba(0, 54, 56, 0.1)",
      display: "flex",
      borderRadius: "16px",
      alignItems: "center",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: 8,
      "&:last-child": {
        marginBottom: 0,
      },
    },
    first: {
      backgroundColor: "#fffde8",
    },
    second: {
      // backgroundColor: "#f9f9f9",
    },
    third: {
      backgroundColor: "#fffbf2",
    },
    ranks: {
      borderRadius: "12px",
      border: "4px solid",
      borderImageSlice: 1,

      "& .topPosName": {
        [theme.breakpoints.down("480")]: {
          fontSize: 14,
        },
        [theme.breakpoints.down("410")]: {
          fontSize: 12,
        },
        [theme.breakpoints.down("354")]: {
          fontSize: 10,
        },
        [theme.breakpoints.down("300")]: {
          fontSize: 9,
        },
      },

      "& .topPosPoints": {
        [theme.breakpoints.down("480")]: {
          fontSize: 12,
        },
        [theme.breakpoints.down("410")]: {
          fontSize: 10,
        },
        [theme.breakpoints.down("354")]: {
          fontSize: 9,
        },
        [theme.breakpoints.down("300")]: {
          fontSize: 8,
        },
      },
    },
    img: {
      marginRight: theme.spacing(2),
      width: "60px",
      height: "60px",
    },
    imgLeader: {
      background:
        "linear-gradient(91.61deg, rgba(0, 54, 56, 0.1) 0%, rgba(114, 201, 116, 0.1) 100%)",
      height: "100px",
      width: "100px",
    },
    imgBadge: {
      width: "100%",
      height: "50px",
      position: "relative",
      top: "-10%",
      left: "26%",
      transform: "translate(-26%, 5%)",
      objectFit: "contain",
      [theme.breakpoints.down("900")]: {
        height: "70px",
      },
      [theme.breakpoints.down("700")]: {
        height: "60px",
      },
      [theme.breakpoints.down("360")]: {
        height: "45px",
      },
    },
    leader1Info: {
      height: "90px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    name: {
      fontWeight: "bold",
      fontSize: 16,
      marginBottom: theme.spacing(1),
    },
    points: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: "bold",
    },
    btn_container: {
      display: "block",
      textDecoration: "none",
      marginBottom: theme.spacing(2),
    },
    ques_container: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2),
      borderRadius: 8,
      backgroundColor: theme.palette.background.paper,
    },
    name_points_position: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    position: {
      // minWidth: 50,
    },
  }),
  { index: 1 }
);
export const useMintPageStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      marginTop: "1em",
      padding: "0em 1.4em",
      display: "flex",
      // [theme.breakpoints.down("xs")]: {
      //   padding: "0em 0.9em",
      //   flexDirection: "column",
      // },
    },
    mintUserInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "40%",
      gap: "1em",
      marginBottom: "2em",
    },
    inputField: {
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      marginTop: "1em",
      marginBottom: "1em",

      "& input": {
        [theme.breakpoints.down("600")]: {
          fontSize: "0.8rem",
        },
      },
      "& textarea": {
        [theme.breakpoints.down("600")]: {
          fontSize: "0.8rem",
        },
      },
    },
    customInputWrapper: {
      height: "150px",
      marginBottom: "3em",
      borderRadius: "5px",
      border: "none",
    },
    customInput: {
      display: "none",
    },
    customLabel: {
      border: "1px solid transparent",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.5em",
      color: "gray",
      cursor: "pointer",
      height: "100%",
      marginTop: "0.4em",
    },
    userCard: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "18.5em",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userNFT: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "12.5em",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "1em",
      [theme.breakpoints.down("xs")]: {
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userReward: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      width: theme.spacing(21),
      height: theme.spacing(30),
      textAlign: "center",
      marginTop: "0.5em",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "12.5em",
        marginBottom: "0.5em",
      },
    },
    avatar: {
      width: theme.spacing("21"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nft: {
      width: theme.spacing("30"),
      height: theme.spacing("30"),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nftCard: {
      width: theme.spacing("10"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    cardText: {
      marginTop: "0.5em",
    },
    infoCard: {
      paddingLeft: "2em",
      paddingRight: "2em",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    userInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    bigText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
    smallText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8em",
      },
    },
    statsBar: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    recentActivity: {
      marginTop: "1em",
    },
    recentQuesAns: {
      display: "flex",
      padding: "0.5em 0",
      alignItems: "center",
      justifyContent: "space-between",
    },
    votesTitleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    votes: {
      padding: "0.2em 0.5em",
      border: "1px solid #d3d3d3",
      marginRight: "0.7em",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0.5em",
      },
    },
    title: {
      textDecoration: "none",
      "&:hover": {
        filter: "brightness(200%)",
      },
      transition: "all 0.4s ease",
    },
  }),
  { index: 1 }
);
export const useUserPageStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      marginTop: "1em",
      display: "flex",
      flexDirection: "column",
      borderRadius: "5px",
      marginBottom: "1em",
      padding: "0.5em 0",
      paddingRight: "1em",
      margin: "0 auto",
      background: "transparent",
      [theme.breakpoints.down("xs")]: {
        padding: "0em 0.9em",
      },
    },
    quesRoot: {
      width: "80%",
      marginTop: "1em",
      display: "flex",
      flexDirection: "column",
      borderRadius: "5px",
      marginBottom: "1em",
      padding: "0.5em 0",
      paddingRight: "1em",
      margin: "0 auto",
      background: "transparent",
      [theme.breakpoints.down("xs")]: {
        padding: "0em 0.9em",
      },
    },
    coverWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    userStatsOuterWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },

    userDetailsOuterWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.5em",
      paddingTop: "2em",
      paddingBottom: "2em",
      color: "#C1C1C1",
    },
    userDetailsInnerWrapper: {
      width: "50%",
      paddingLeft: "5em",
      paddingBottom: "1em",
      borderBottom: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
    },
    userDetailsIconWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "14px",
    },
    postFieldOuterWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    postFieldInnerWrapper: {
      width: "50%",
    },
    customInput: {
      height: "100%",
      padding: "0px 5px",

      border: "1px solid transparent",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInputLabel-animated": {
        paddingLeft: "0.5em",
      },
    },
    userCard: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "18.5em",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userNFT: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "12.5em",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "1em",
      [theme.breakpoints.down("xs")]: {
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userReward: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      width: theme.spacing(21),
      height: theme.spacing(30),
      textAlign: "center",
      marginTop: "0.5em",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "12.5em",
        marginBottom: "0.5em",
      },
    },
    avatar: {
      width: theme.spacing("21"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nft: {
      width: theme.spacing("30"),
      height: theme.spacing("30"),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nftCard: {
      width: theme.spacing("10"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    cardText: {
      marginTop: "0.5em",
    },
    infoCard: {
      paddingLeft: "2em",
      paddingRight: "2em",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    userInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    bigText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
    smallText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8em",
      },
    },
    statsBar: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    recentActivity: {
      marginTop: "1em",
    },
    recentQuesAns: {
      display: "flex",
      padding: "0.5em 0",
      alignItems: "center",
      justifyContent: "space-between",
    },

    // Questions

    titleAnsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    titleStatsWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      height: "80px",
    },

    answers: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      padding: "0.2em 0.5em",
      marginRight: "0.7em",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0.5em",
      },
    },

    infoWrapper: {
      display: "flex",
    },
    viewWrapper: {
      display: "flex",
      alignItems: "center",
      width: "60px",
      gap: "5px",
    },
    voteWrapper: {
      display: "flex",
      alignItems: "center",
      width: "60px",
      gap: "5px",
    },
    ansWrapper: {
      display: "flex",
      alignItems: "center",
      width: "60px",
      gap: "5px",
    },
    mainText: {
      fontSize: "8px",
      fontWeight: 500,
      color: "#949494",
    },
    title: {
      fontSize: "1.2em",
      fontWeight: 500,
      wordWrap: "anywhere",
      textDecoration: "none",
      "&:hover": {
        filter: "brightness(200%)",
      },
      transition: "all 0.4s ease",
    },
    innerInfo: {
      display: "flex",
      flexDirection: "column",
    },
    quesDetails: {
      marginLeft: "35px",
      paddingLeft: "0.2em",
      paddingBottom: "1.2em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      borderBottom: "3px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
    },
    quesImg: {
      objectFit: "contain",
      maxWidth: "100%",
      height: "auto",
      maxHeight: "300px",
      width: "100%",
      cursor: "pointer",
    },
    tagsWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    tag: {
      marginRight: "0.5em",
      marginTop: "0.5em",
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
    },
    bottomWrapper: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
    byUserWrapper: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.7em",
    },
    filledByUser: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.7em",
      backgroundColor: `${theme.palette.primary.main}10`,
      padding: "0.4em",
      borderRadius: 3,
      border: `1px solid ${theme.palette.primary.main}40`,
    },
    homeAvatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: "0.4em",
      borderRadius: "50%",
    },
    quesAnsAvatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginRight: "0.4em",
      borderRadius: "2px",
    },
  }),
  { index: 1 }
);

export const useMarketPageStyles = makeStyles(
  (theme) => ({
    NFTCardWrapper: {
      background: "#181F22",
      borderRadius: "1em",
      padding: "2em 0",
      textAlign: "center",
      display: "flex",
      flex: "0 0 40%",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1em",
      maxWidth: "300px",

      [theme.breakpoints.down("1100")]: {
        flex: "0 0 50%",
        marginBottom: "0.5em",
      },
      [theme.breakpoints.down("850")]: {
        flex: "0 0 60%",
        marginBottom: "0.5em",
        minWidth: "300px",
      },
      [theme.breakpoints.down("600")]: {
        flex: "0 0 100%",
        marginBottom: "0.5em",
        maxWidth: "280px",
      },
      [theme.breakpoints.down("400")]: {
        flex: "0 0 100%",
        marginBottom: "0.5em",
        maxWidth: "200px",
      },
    },
    NFTMobCardWrapper: {
      background: "#181F22",
      borderRadius: "1em",
      padding: "2em 0",
      width: "50px",
      textAlign: "center",
      display: "flex",
      flex: "0 0 50%",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      margin: "1em",
      [theme.breakpoints.down("xs")]: {
        flex: "0 0 60%",
        marginBottom: "0.5em",
      },
    },
    NFTCardInfo: {
      padding: "0 1em",
      width: "100%",
      [theme.breakpoints.down("400")]: {
        width: "80%",
      },
    },
    NFTCardInfoInnerWrapper: {
      padding: "0 1em",
    },
    NFTImg: {
      width: "100%",
      height: "auto",
      maxHeight: "200px",
      objectFit: "contain",
    },
    NFTName: {
      fontSize: "18px",
    },
    NFTDesc: {
      fontSize: "12px",
    },
    buyNFTBtn: {
      fontSize: "16px",
      background: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      borderRadius: "0.5em",
      padding: "0.3em 1em",
    },
    root: {
      margin: "1em 0",
      display: "flex",
      justifyContent: "center",
      gap: "2em",
      [theme.breakpoints.down("600")]: {
        with: "80%",
      },
    },
    marketWrapper: {
      overflowY: "auto",
      display: "flex",
      justifyContent: "center",
      "&::-webkit-scrollbar": {
        width: "15px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "linear-gradient(91.6deg, #003638 0%, #72C974 70%)",
        borderRadius: "10px",
      },
    },
    menuBar: {
      marginTop: "1em",
      padding: "0em 1.4em",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("850")]: {
        display: "none",
      },
    },
    nftList: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      [theme.breakpoints.down("600")]: {
        width: "80%",
      },
    },

    buttonList: {
      color: "#ffffff",
      marginTop: "1em",
      background: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      "&.Mui-disabled": {
        color: "#FFFFFF",
        background: "#808080",
      },
    },
    userCard: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      height: "18.5em",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "16em",
        marginBottom: "0.5em",
      },
    },
    userNFT: {
      padding: "1.6em",
      width: "100%",
      textAlign: "center",
      display: "flex",
      flex: "0 0 30%",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      margin: "1em",
      [theme.breakpoints.down("850")]: {
        flex: "0 0 60%",
        marginBottom: "0.5em",
      },
      [theme.breakpoints.down("xs")]: {
        flex: "0 0 100%",
        marginBottom: "0.5em",
      },
    },
    userNFTMob: {
      border: "2px solid",
      borderRadius: "20px",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.6deg,#003638 0%, #72C974 70%)",
      padding: "1.6em",
      width: "50px",
      // height: "12.5em",
      textAlign: "center",
      display: "flex",
      flex: "0 50%",
      flexDirection: "column",
      justifyItems: "center",
      alignItems: "center",
      margin: "1em",
      [theme.breakpoints.down("xs")]: {
        // height: "16em",
        flex: "0 0 60%",
        marginBottom: "0.5em",
      },
    },
    userReward: {
      backgroundColor: `${theme.palette.primary.main}15`,
      padding: "1.6em",
      width: theme.spacing(21),
      height: theme.spacing(30),
      textAlign: "center",
      marginTop: "0.5em",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "12.5em",
        marginBottom: "0.5em",
      },
    },
    avatar: {
      width: "300px",
      height: "200px",
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nft: {
      width: theme.spacing("30"),
      height: theme.spacing("30"),
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    nftCard: {
      width: theme.spacing("10"),
      height: theme.spacing(21),
      borderRadius: 3,
      [theme.breakpoints.down("xs")]: {
        width: theme.spacing(15),
        height: theme.spacing(15),
      },
    },
    cardText: {
      marginTop: "0.5em",
    },
    priceTag: {
      marginTop: "0.5em",
    },
    infoCard: {
      paddingLeft: "2em",
      paddingRight: "2em",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    userInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    bigText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5em",
      },
    },
    smallText: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8em",
      },
    },
    statsBar: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
    },
    recentActivity: {
      marginTop: "1em",
    },
    recentQuesAns: {
      display: "flex",
      padding: "0.5em 0",
      alignItems: "center",
      justifyContent: "space-between",
    },
    votesTitleWrapper: {
      display: "flex",
      alignItems: "center",
    },
    votes: {
      padding: "0.2em 0.5em",
      border: "1px solid #d3d3d3",
      marginRight: "0.7em",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0.5em",
      },
    },
    title: {
      textDecoration: "none",
      "&:hover": {
        filter: "brightness(200%)",
      },
      transition: "all 0.4s ease",
    },
  }),
  { index: 1 }
);

export const useAskQuesPageStyles = makeStyles(
  (theme) => ({
    root: {
      color: "#FFFFFF",
      width: "100%",
      background: "#111618",
      height: "100%",
    },
    modalHeading: {
      [theme.breakpoints.down("600")]: {
        fontSize: "1.2rem",
      },
    },
    quesForm: {
      paddingTop: "0.8em",
    },
    userInfoWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    userInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "1em",
      marginBottom: "2em",
      borderRadius: "5px",
      border: "none",
    },
    inputWrapper: {
      marginBottom: "2em",
      borderRadius: "5px",
      border: "none",
    },
    customInputWrapper: {
      height: "150px",
      marginBottom: "4em",
      borderRadius: "5px",
      border: "none",
    },
    customInput: {
      display: "none",
    },
    customLabel: {
      border: "1px solid transparent",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.5em",
      color: "gray",
      cursor: "pointer",
      height: "100%",
      marginTop: "0.4em",
    },

    inputField: {
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      marginTop: "0.4em",

      "& input": {
        [theme.breakpoints.down("600")]: {
          fontSize: "0.8rem",
        },
      },
      "& textarea": {
        [theme.breakpoints.down("600")]: {
          fontSize: "0.8rem",
        },
      },
    },
    submitBtn: {
      color: "#FFFFFF",
      width: "100%",
      background: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    tag: {
      marginRight: "0.5em",
    },
    closeBtn: {
      color: "#ffffff",
      padding: "5px 3px",
      position: "absolute",
      right: "0",
    },
  }),
  { index: 1 }
);

export const useVoteBtnsStyles = makeStyles(
  (theme) => ({
    icon: {
      color: "#aa9aaa",
      fontSize: 32,
    },
    checkedIcon: {
      color: "#964ec2",
      fontSize: 32,
    },
  }),
  { index: 1 }
);

export const useAlertStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      marginTop: "0.8em",
      marginBottom: "0.8em",
    },
  }),
  { index: 1 }
);

export const useSettingsPageStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      padding: "0.4em 0.7em",
      paddingBottom: "100px",
      backgroundColor: "#111618",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0.3em",
        paddingRight: "0.3em",
        border: "none",
      },
    },
    accordianWrapper: {
      width: "90%",
      padding: "1em 0",
    },
    accordian: {
      background: "transparent",
      borderBottom: "2px solid",
      borderImageSource: "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
      borderImageSlice: 1,
    },
    accordianContent: {
      justifyContent: "center",
    },
    deleteBtn: {
      background: "#BF0404",
      color: "#FFFFFFF",
      width: "100%",
      padding: "8px 22px",
    },
    accordianHeading: {
      fontWeight: "bold",
    },
    filePickerBtn: {
      height: "100px",
      width: "100px",
      borderRadius: "50%",
      backgroundSize: "cover",
    },
    userInfoWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    userInfo: {
      height: "150px",
      width: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    editBtnWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
    updateBtn: {
      background: theme.palette.primary.own,
      marginTop: "2rem",
      color: "#FFFFFF",
      width: "100%",
    },
    deleteModal: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#111618",
      color: "#FFFFFF",
      boxShadow: "24",
      padding: "4",
      height: "250px",
      width: "400px",
      borderRadius: "20px",
    },
    modalBtnsWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
    },
  }),
  { index: 1 }
);

export const useNotFoundPageStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      marginTop: "1em",
      padding: "0.4em 1em",
    },
    textWrapper: {
      marginTop: "15%",
      textAlign: "center",
    },
    icon: {
      fontSize: "6em",
      marginBottom: "0.3em",
    },
  }),
  { index: 1 }
);
