import React, { useState } from "react";
import { useSettingsPageStyles } from "../styles/muiStyles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  Typography,
} from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ConnectWallet from "../components/ConnectWallet";
import DeleteAccountModal from "../components/DeleteAccountModal";

const SettingsPage = () => {
  const classes = useSettingsPageStyles();
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState("Ziyaan Ali");
  const [email, setEmail] = useState("ziyaanAli559@gmail.com");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteClick = () => {
    console.log("here");
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    // Put your logic for deleting the account here
    setDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs={12} className={classes.accordianWrapper}>
            <Accordion className={classes.accordian}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.accordianHeading}>
                  Account
                </Typography>
              </AccordionSummary>
              <div className={classes.editBtnWrapper}>
                <Button onClick={handleEditClick}>
                  <EditOutlinedIcon />
                </Button>
              </div>
              <AccordionDetails className={classes.accordianContent}>
                <div className={classes.userInfoWrapper}>
                  <Input
                    type="file"
                    style={{ display: "none" }}
                    inputProps={{ accept: "image/*" }}
                    id="file-input"
                  />
                  <label htmlFor="file-input">
                    <Button
                      className={classes.filePickerBtn}
                      style={{
                        backgroundImage: `url("/images/default-bg.png")`,
                      }}
                    />
                  </label>
                  <div className={classes.userInfo}>
                    {editMode ? (
                      <>
                        <Input
                          fullWidth
                          value={name}
                          onChange={handleNameChange}
                          placeholder="Name"
                          style={{ margin: "0.5em 0" }}
                        />
                        <Input
                          fullWidth
                          value={email}
                          onChange={handleEmailChange}
                          placeholder="Email"
                        />
                        <Button className={classes.updateBtn}>Update</Button>
                      </>
                    ) : (
                      <>
                        <Typography>Name: {name}</Typography>
                        <Typography>Email: {email}</Typography>
                        <Typography style={{ textDecoration: "underline" }}>
                          Reset Password?
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} className={classes.accordianWrapper}>
            <Accordion className={classes.accordian}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.accordianHeading}>
                  Wallet
                </Typography>
              </AccordionSummary>

              <AccordionDetails className={classes.accordianContent}>
                <ConnectWallet className={classes.settingsBtnWrapper} />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12} className={classes.accordianWrapper}>
            <Accordion className={classes.accordian}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.accordianHeading}>
                  Delete Account
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordianContent}>
                <div>
                  <Typography style={{ marginBottom: "1em" }}>
                    Deleting your account will delete all your posts and NFTs
                    you minted.
                  </Typography>

                  <Button
                    className={classes.deleteBtn}
                    onClick={handleDeleteClick}
                  >
                    Delete account
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Modal open={isDeleteModalOpen} onClose={handleDeleteCancel}>
          <DeleteAccountModal
            open={isDeleteModalOpen}
            onClose={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
          />
        </Modal>
      </div>
    </>
  );
};

export default SettingsPage;
