import { useState } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";

import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Link,
} from "@material-ui/core";
import { useMenuStyles } from "../styles/muiStyles";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import PublicIcon from "@material-ui/icons/Public";
import RewardIcon from "@material-ui/icons/EmojiEventsOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PeopleIcon from "@material-ui/icons/People";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import StoreIcon from "@material-ui/icons/Store";

const MobileNavMenu = () => {
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useMenuStyles();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton color="primary" onClick={handleOpenMenu}>
        {!anchorEl ? <MenuIcon /> : <CloseIcon className={classes.closeIcon} />}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        marginThreshold={0}
        elevation={1}
      >
        <MenuItem
          selected={
            pathname === "/" ||
            (!pathname.startsWith("/tag") && !pathname.startsWith("/leader"))
          }
          dense
          component={RouterLink}
          to="/"
          onClick={handleCloseMenu}
        >
          <PublicIcon className={classes.menuIcon} />
          Puchooo
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/reward")}
          dense
          component={RouterLink}
          to="/reward"
          onClick={handleCloseMenu}
        >
          <RewardIcon className={classes.menuIcon} />
          Reward
        </MenuItem>
        {/* <MenuItem
          selected={pathname.startsWith("/tag")}
          dense
          component={RouterLink}
          to="/tags"
          onClick={handleCloseMenu}
        >
          <LocalOfferIcon className={classes.menuIcon} />
          Tags
        </MenuItem> */}
        <MenuItem
          selected={pathname.startsWith("/leader")}
          dense
          component={RouterLink}
          to="/leaderboard"
          onClick={handleCloseMenu}
        >
          <PeopleIcon className={classes.menuIcon} />
          Leaderboard
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/mint")}
          dense
          component={RouterLink}
          to="/mint"
          onClick={handleCloseMenu}
        >
          <WalletIcon className={classes.menuIcon} />
          Mint
        </MenuItem>
        <MenuItem
          selected={pathname.startsWith("/market")}
          dense
          component={RouterLink}
          to="/marketplace"
          onClick={handleCloseMenu}
        >
          <StoreIcon className={classes.menuIcon} />
          Marketplace
        </MenuItem>
        <Divider />
        {/* <div className={classes.madeByItem}>
          <Typography variant="caption" color="secondary">
            Made with{" "}
            <FavoriteIcon style={{ fontSize: 10, color: "#f4649f" }} /> by{" "}
            <Link
              href={"https://irenictech.com"}
              color="inherit"
              target="_blank"
              rel="noopener"
            >
              <strong>{` irenictech`}</strong>
            </Link>
          </Typography>
        </div> */}
      </Menu>
    </div>
  );
};

export default MobileNavMenu;
