import React, { useState, useRef, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import * as dotenv from "dotenv";
import axios from "axios";
import { GET_USER } from "../graphql/queries";
import {
  REGISTER_USER,
  REWARD_USER,
  LIST_NFT,
  BUY_NFT,
} from "../graphql/mutations";
import { useQuery } from "@apollo/client";
import RecentQuestions from "../components/RecentQuestions";
import LoadingSpinner from "../components/LoadingSpinner";
import PersonIcon from "@material-ui/icons/Person";
import { useStateContext } from "../context/state";
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";
import { abi, IR_CONTRACT_ADDRESS } from "../constants";
import Marketplace from "../constants/Marketplace.json";
import { ethers, Contract, providers, utils } from "ethers";
import { pinJSONToIPFS, GetIpfsUrlFromPinata } from "../utils/pinata";
import {
  TextField,
  Avatar,
  Typography,
  Divider,
  Button,
  InputAdornment,
  Link,
  Chip,
  Box,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useMarketPageStyles } from "../styles/muiStyles";
import { useAuthContext } from "../context/auth";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import ConnectWallet from "../components/ConnectWallet";
import { GET_ALL_COLLECTIONS } from "../graphql/queries";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
} from "../utils/interact.js";
import selected_desktop from "../svg/selected_desktop.svg";
import non_selected_desktop from "../svg/non_selected_desktop.svg";
import selected_mob from "../svg/selected_mob.svg";
import non_selected_mob from "../svg/non_selected_mob.svg";
import menu_divider from "../svg/menu_divider.svg";
import { Tune } from "@material-ui/icons";

const MarketPage = ({ userID }) => {
  const { address, isConnected } = useAccount();
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_ALCHEMY_API_URL
  );
  const wallet = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY, provider);
  const contract = new ethers.Contract(IR_CONTRACT_ADDRESS, abi, wallet);

  const { data, loading } = useQuery(GET_ALL_COLLECTIONS, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });

  const [nftList, { loading: nftListLoading }] = useMutation(LIST_NFT, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });
  const [nftBuy, { loading: nftBuyLoading }] = useMutation(BUY_NFT, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });

  const classes = useMarketPageStyles();
  const { notify } = useStateContext();
  const [sdesktop, setSDesktop] = useState(true);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const [isListing, setIsListing] = useState(false); // State to manage the input box visibility
  const [listingPrice, setListingPrice] = useState(""); // State to store the price to list the NFT
  const [isListingMap, setIsListingMap] = useState({});

  // Function to open the input box when "List" button is clicked
  const handleListButtonClick = (itemID) => {
    setIsListingMap((prevMap) => ({
      ...prevMap,
      [itemID]: !prevMap[itemID], // Toggle the state for the clicked item
    }));
  };

  // Function to handle "Done" button click and list the NFT
  const handleListNFT = async (nftID, name, description, lprice, image) => {
    await listNFT(nftID, name, description, lprice, image);
    setIsListing(false);
  };

  // Function to handle "Buy" button click
  const handleBuyNFT = async (nftID, tokenID, price) => {
    await buyNFT(nftID, tokenID, price);
  };

  useEffect(() => {}, [isListing]);

  async function listNFT(nftID, name, description, lprice, image) {
    //Upload data to IPFS
    try {
      const metadataURL = await pinJSONToIPFS({
        name,
        description,
        price: lprice,
        image,
      });

      //After adding your Hardhat network to your metamask, this code will get providers and signers
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner(address);

      //Pull the deployed contract instance
      let contract = new ethers.Contract(
        Marketplace.address,
        Marketplace.abi,
        signer
      );

      //massage the params to be sent to the create NFT request
      const price_eth = ethers.utils.parseUnits(lprice, "ether");
      let listingPrice = await contract.getListPrice();
      listingPrice = listingPrice.toString();

      //actually create the NFT
      let transaction = await contract.createToken(
        `${metadataURL.pinataUrl}`,
        price_eth,
        {
          value: listingPrice,
        }
      );

      await transaction.wait();
      nftList({
        variables: { id: nftID, price: parseFloat(lprice), seller: address },
        update: (_, { data }) => {
          notify("Successfully listed your NFT!");
          setTimeout(function () {
            window.location.reload(true);
          }, 2000);
        },
      });
    } catch (e) {
      notify("Listing error" + e);
      console.log("error", e);
    }
  }

  async function buyNFT(nftID, tokenID, price) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      //Pull the deployed contract instance
      let contract = new ethers.Contract(
        Marketplace.address,
        Marketplace.abi,
        signer
      );
      const salePrice = ethers.utils.parseUnits(price.toString(), "ether");
      //run the executeSale function
      let transaction = await contract.executeSale(tokenID, {
        value: salePrice,
      });
      await transaction.wait();

      nftBuy({
        variables: { id: nftID, tokenID, account: address },
        update: (_, { data }) => {
          notify("Successfully Bought NFT!");
          setTimeout(function () {
            window.location.reload(true);
          }, 2000);
        },
      });
    } catch (e) {
      notify("Buying Error" + e);
    }
  }
  if (loading || nftBuyLoading || nftListLoading) {
    return (
      <div style={{ minWidth: "100%", marginTop: "20%" }}>
        <LoadingSpinner size={80} />
      </div>
    );
  }
  return (
    <Box>
      {!userID && (
        <div className={classes.menuBar}>
          <img
            style={{ cursor: "pointer" }}
            typeof="button"
            height="30px"
            width="30px"
            src={sdesktop ? selected_desktop : non_selected_desktop}
            onClick={() => setSDesktop(true)}
          />
          <img
            style={{ cursor: "pointer" }}
            typeof="button"
            height="30px"
            width="30px"
            src={menu_divider}
          />
          <img
            style={{ cursor: "pointer" }}
            typeof="button"
            height="30px"
            width="30px"
            src={!sdesktop ? selected_mob : non_selected_mob}
            onClick={() => setSDesktop(false)}
          />
        </div>
      )}

      <Grid container className={classes.root}>
        {!loading && data && !userID ? (
          <div className={classes.nftList}>
            {data.getAllCollections.length === 0 ? (
              <div className={classes.noNFTsFound}>No NFTs found.</div>
            ) : (
              data.getAllCollections.map((u) =>
                u.listNFT ? (
                  <div
                    key={u.id}
                    className={
                      sdesktop
                        ? classes.NFTCardWrapper
                        : classes.NFTMobCardWrapper
                    }
                  >
                    <div className={classes.NFTCardImg}>
                      <img
                        className={classes.NFTImg}
                        src={u.imgLink}
                        alt={u.title}
                      />
                    </div>
                    <div className={classes.NFTCardInfo}>
                      <div className={classes.NFTCardInfoInnerWrapper}>
                        <h3
                          className={classes.NFTName}
                        >{`Title: ${u.title}`}</h3>
                        <p className={classes.NFTDesc}>{u.description}</p>
                        {u.price !== "0" && (
                          <Button
                            className={classes.buyNFTBtn}
                          >{`Buy for ${u.price} ETH`}</Button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )
            )}
          </div>
        ) : (
          <div className={classes.root}>
            {!loading && data && userID ? (
              <div className={classes.nftList}>
                {data.getAllCollections.length === 0 ||
                data.getAllCollections.filter((item) => item.userID === userID)
                  .length === 0 ? (
                  <div className={classes.noNFTsFound}>No NFTs found.</div>
                ) : (
                  data.getAllCollections
                    .filter((item) => item.userID === userID)
                    .map((u) => (
                      <div
                        key={u.id}
                        className={
                          sdesktop ? classes.userNFT : classes.userNFTMob
                        }
                      >
                        <div
                          key={u.id}
                          className={
                            sdesktop
                              ? classes.NFTCardWrapper
                              : classes.NFTMobCardWrapper
                          }
                        >
                          <div className={classes.NFTCardImg}>
                            <img
                              className={classes.NFTImg}
                              src={u.imgLink}
                              alt={u.title}
                            />
                          </div>
                          <div className={classes.NFTCardInfo}>
                            <div className={classes.NFTCardInfoInnerWrapper}>
                              <h3
                                className={classes.NFTName}
                              >{`Title: ${u.title}`}</h3>
                              <p className={classes.NFTDesc}>{u.description}</p>
                              {isConnected &&
                                address === u.owner &&
                                userID === u.userID && (
                                  <>
                                    {!isListingMap[u.id] ? (
                                      <>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          size="large"
                                          fullWidth
                                          disabled={u.listNFT}
                                          className={classes.buttonList}
                                          onClick={() =>
                                            handleListButtonClick(u.id)
                                          } // Open the input box
                                        >
                                          {u.listNFT ? "Listed" : "List"}
                                        </Button>
                                      </>
                                    ) : (
                                      <div>
                                        <TextField
                                          label="Price"
                                          variant="outlined"
                                          fullWidth
                                          value={listingPrice}
                                          placeholder="Min 0.01 ETH"
                                          style={{
                                            marginTop: "1em",
                                          }}
                                          onChange={(e) =>
                                            setListingPrice(e.target.value)
                                          }
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={() =>
                                                    handleListNFT(
                                                      u.id,
                                                      u.title,
                                                      u.description,
                                                      listingPrice,
                                                      u.imgLink
                                                    )
                                                  } // Handle listing NFT
                                                >
                                                  Done
                                                </Button>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>
            ) : (
              <div style={{ minWidth: "100%" }}>
                <LoadingSpinner size={80} />
              </div>
            )}
          </div>
        )}
      </Grid>
    </Box>
  );
};

export default MarketPage;
