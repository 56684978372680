import { Link as RouterLink } from "react-router-dom";
import { formatDateAgo } from "../utils/helperFuncs";

import { Chip, Paper, Typography } from "@material-ui/core";
import { useUserPageStyles } from "../styles/muiStyles";
import { QuestionMarkOutlined } from "@mui/icons-material";

const RecentQuestions = ({ question }) => {
  console.log(question);
  const classes = useUserPageStyles();

  return (
    <Paper elevation={0} className={classes.quesRoot}>
      <div className={classes.quesDetails}>
        <div className={classes.titleAnsWrapper}>
          <div className={classes.titleStatsWrapper}>
            <Typography
              variant="body2"
              color="secondary"
              className={classes.title}
              component={RouterLink}
              to={`/questions/${question.id}`}
            >
              {question.title}
            </Typography>

            <div className={classes.infoWrapper}>
              <div className={classes.viewWrapper}>
                <img src="/icons/icon-view.png" alt="view" />
                <Typography variant="body2" className={classes.mainText}>
                  {question.points}
                </Typography>
                <Typography className={classes.mainText}>views</Typography>
              </div>
              <div className={classes.voteWrapper}>
                <img src="/icons/icon-sm-thumbs-up.png" alt="vote" />
                <Typography variant="body2" className={classes.mainText}>
                  {question.points}
                </Typography>
                <Typography className={classes.mainText}>votes</Typography>
              </div>
              <div className={classes.ansWrapper}>
                <img src="/icons/icon-answer.png" alt="answer" />
                <Typography variant="body2" className={classes.mainText}>
                  {question.answerCount}
                </Typography>
                <Typography className={classes.mainText}>answers</Typography>
              </div>
            </div>
          </div>

          <div className={classes.answers}>
            <Typography color="primary" variant="subtitle2">
              <div>{question.points}</div>
              <div>Answers</div>
            </Typography>
          </div>
        </div>

        <div className={classes.bottomWrapper}>
          <div className={classes.tagsWrapper}>
            {/* {question.tags.map((t) => (
                <Chip
                  key={t}
                  label={t}
                  variant="outlined"
                  color="primary"
                  size="small"
                  component={RouterLink}
                  to={`/tags/${t}`}
                  className={classes.tag}
                  clickable
                />
              ))} */}
            <Chip
              // key={t}
              label="tag"
              variant="outlined"
              color="primary"
              size="small"
              component={RouterLink}
              // to={`/tags/${t}`}
              className={classes.tag}
              clickable
            />
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default RecentQuestions;
