import { useState, useRef, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as dotenv from "dotenv";
import { GET_USER, GET_QUESTIONS } from "../graphql/queries";
import { GET_ALL_COLLECTIONS } from "../graphql/queries";
import { REGISTER_USER, REWARD_USER } from "../graphql/mutations";
import RecentQuestions from "../components/RecentQuestions";
import ProfileTabBar from "../components/ProfileTabBar";
import LoadingSpinner from "../components/LoadingSpinner";
import { useStateContext } from "../context/state";
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";
import { abi, IR_CONTRACT_ADDRESS } from "../constants";
import { ethers, Contract, providers, utils } from "ethers";
import {
  Avatar,
  Typography,
  Divider,
  Button,
  useMediaQuery,
  Link,
  Input,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useUserPageStyles, useMarketPageStyles } from "../styles/muiStyles";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Grid, Chip } from "@mui/material";
import CardMedia from "@material-ui/core/CardMedia";
import { useAuthContext } from "../context/auth";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import ConnectWallet from "../components/ConnectWallet";
import MarketPage from "./MarketPlace";

import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "250px",
    position: "relative",
  },
  coverPhoto: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    top: 60,
    zIndex: 2,
    border: `5px solid ${theme.palette.background.paper}`,
  },
}));

const useprofileDetailStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  userContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  user_details_container: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  details_area: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  details: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  detailtxt: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white",
    marginBottom: theme.spacing(1),
  },
  detailInfo: {
    color: "white",
    marginLeft: theme.spacing(1),
  },
}));

const UserPage = ({ handleClickOpenEditModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [changeBy, setChangeBy] = useState("Profile");
  const [sortBy, setSortBy] = useState("NEWEST");
  const { address, isConnected } = useAccount();
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_ALCHEMY_API_URL
  );
  const wallet = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY, provider);
  const contract = new ethers.Contract(IR_CONTRACT_ADDRESS, abi, wallet);
  const { user } = useAuthContext();
  const classes = useUserPageStyles();
  const profileclasses = useStyles();
  const profiledetailsclasses = useprofileDetailStyles();
  const market_classes = useUserPageStyles();
  const { notify } = useStateContext();
  const { username } = useParams();
  const [errorMsg, setErrorMsg] = useState(null);
  const [sdesktop, setSDesktop] = useState(true);
  const [fetchedUser, setFetchedUser] = useState(null);
  const [userID, setUserID] = useState("");
  const [balance, setBalance] = useState("");
  const [transaction, setTransaction] = useState(false);
  const [fetchUser, { data, loading }] = useLazyQuery(GET_USER, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });
  const [fetchQuestions, { qdata, loading: qloading }] = useLazyQuery(
    GET_QUESTIONS,
    {
      fetchPolicy: "network-only",
      onError: (err) => {
        notify(getErrorMsg(err), "error");
      },
    }
  );
  const getQues = (sortBy, page, limit, filterByTag, filterBySearch) => {
    fetchQuestions({
      variables: { sortBy, page, limit, filterByTag, filterBySearch },
    });
  };
  const [userReward, { data: rewardData, loading: rewardloading, error }] =
    useMutation(REWARD_USER, {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    });

  const { data: userNFT, loading: userNFTLoading } = useQuery(
    GET_ALL_COLLECTIONS,
    {
      onError: (err) => {
        notify(getErrorMsg(err), "error");
      },
    }
  );

  useEffect(() => {
    fetchUser({ variables: { username } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    if (data) {
      setFetchedUser(data.getUser);
      setUserID(data.getUser.id);
    }
  }, [data]);

  useEffect(async () => {
    const decimals = await contract.decimals();
    const symbol = await contract.symbol();
    if (address) {
      contract.balanceOf(address).then(function (bal) {
        setBalance(`${ethers.utils.formatUnits(bal, decimals)} ${symbol}`);
      });
    }
  });

  if (loading || !fetchedUser) {
    return (
      <div style={{ minWidth: "100%", marginTop: "20%" }}>
        <LoadingSpinner size={80} />
      </div>
    );
  }

  const {
    id,
    account: account,
    rewards: rewards,
    username: userName,
    createdAt,
    reputation,
    totalQuestions,
    totalAnswers,
    recentQuestions,
    recentAnswers,
  } = fetchedUser;

  const ClaimRewards = async () => {
    if (rewards === "notClaimed") {
      setTransaction(true);
      await contract
        .transfer(account, ethers.utils.parseEther("1", "18"))
        .then((tx) => {
          if (tx) {
            userReward({
              variables: {
                id: userID.toString(),
                input: { rewards: "Claimed" },
              },
              update: (_, { data }) => {
                notify(`You've successfully claimed.`);
                setTimeout(function () {
                  window.location.reload(true);
                }, 3000);
              },
            });
          }
        })
        .catch((er) => {
          notify(`Transaction failed ${er} .`, "error");
        });
    }
  };
  return (
    <div className={classes.root}>
      <ProfileTabBar
        isMobile={isMobile}
        changeBy={changeBy}
        setChangeBy={setChangeBy}
      />

      {changeBy === "Profile" && (
        <>
          <div className={profileclasses.coverWrapper}>
            <Card className={profileclasses.root}>
              <CardMedia
                className={profileclasses.coverPhoto}
                image={
                  "https://miro.medium.com/v2/resize:fit:720/format:webp/1*w2Eec-hEt9pCU376JCxRGw.jpeg"
                }
                title="Cover Photo"
              />
              <Avatar
                className={profileclasses.avatar}
                alt={"ok"}
                src={`https://secure.gravatar.com/avatar/${id}?s=164&d=identicon`}
              />
            </Card>
          </div>

          <Grid container className={profiledetailsclasses.root}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={profiledetailsclasses.userContainer}
            >
              <Typography variant="h4">{username}</Typography>
            </Grid>

            <Grid container className={classes.userDetailsOuterWrapper}>
              <div className={classes.userDetailsInnerWrapper}>
                <Grid item style={{ fontWeight: "bold", padding: "0.5em 0" }}>
                  Details
                </Grid>
                <Grid item className={classes.userDetailsIconWrapper}>
                  <BusinessOutlinedIcon /> IrenicTech
                </Grid>
                <div className={classes.userDetailsIconWrapper}>
                  <HomeOutlinedIcon /> Lives in Karachi, Pakistan
                </div>
                <Grid item className={classes.userDetailsIconWrapper}>
                  <WorkOutlineIcon /> Trader
                </Grid>
              </div>
            </Grid>

            <div className={classes.postFieldOuterWrapper}>
              <Grid container style={{ width: "56%" }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="What's on your mind..."
                  className={classes.customInput}
                  InputProps={{
                    style: {
                      margin: "6px 0",
                    },
                  }}
                />
              </Grid>
            </div>
          </Grid>
        </>
      )}

      {changeBy === "Questions" && (
        <div style={{ marginBottom: "1em" }}>
          {recentQuestions.length !== 0 ? (
            recentQuestions.map((q) => (
              <div key={q.id}>
                <RecentQuestions question={q} />
              </div>
            ))
          ) : (
            <Typography variant="subtitle1">No questions asked yet.</Typography>
          )}
        </div>
      )}
      {changeBy === "NFT" && <MarketPage userID={user.id} />}
    </div>
  );
};

export default UserPage;
