import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_USERS } from "../graphql/queries";
import { Link as RouterLink } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import { useStateContext } from "../context/state";
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Height, Menu as MenuIcon } from "@material-ui/icons";
import leaderImg from "../svg/leaderImg.svg";
import badge1 from "../svg/medal_1.svg";
import badge2 from "../svg/medal_2.svg";
import badge3 from "../svg/medal_3.svg";

import {
  Typography,
  TextField,
  InputAdornment,
  Avatar,
  Link,
  Box,
  Paper,
  CardMedia,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import { useLeaderPageStyles } from "../styles/muiStyles";
import SearchIcon from "@material-ui/icons/Search";

const Leaderboard = () => {
  const { notify } = useStateContext();
  const { data, loading } = useQuery(GET_ALL_USERS, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });

  const [filterInput, setFilterInput] = useState("");
  const classes = useLeaderPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("360"));
  let sortedUsers = [];
  const getOrdinalNumber = (number) => {
    const suffixes = [
      "th",
      "st",
      "nd",
      "rd",
      "th",
      "th",
      "th",
      "th",
      "th",
      "th",
    ];
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    return (
      number +
      (lastTwoDigits >= 11 && lastTwoDigits <= 13
        ? "th"
        : suffixes[lastDigit] || suffixes[0])
    );
  };

  if (data) {
    sortedUsers = [...data.getAllUsers].sort(
      (userA, userB) => (userB?.reputation || 0) - (userA?.reputation || 0)
    );
  }

  const usersWithPosition = sortedUsers?.map((item, index) => ({
    ...item,
    position: getOrdinalNumber(index + 1),
  }));

  return (
    <div className={classes.root}>
      {!loading && data ? (
        <>
          <Grid container className={classes.leaderMain}>
            <Grid item xs={12} lg={6} className={classes.leaderboardContainer}>
              <Paper className={classes.leaderpositionItem}>
                <Grid
                  container
                  columnGap={2}
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    gap: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid
                    item
                    xs={isMobile ? 6 : ""}
                    lg={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ height: "170px" }}>
                      <CardMedia
                        component="img"
                        src={`https://secure.gravatar.com/avatar/${usersWithPosition[3].id}?s=164&d=identicon`}
                        className={classes.imgLeader}
                        style={{
                          padding: "2px",
                          marginTop: isMobile ? "-0.5em" : "0.5em",
                          borderRadius: "12px",
                          border: "4px solid",
                          borderImageSlice: 1,
                          borderImageSource:
                            "linear-gradient(180deg, #0083FD 0%, rgba(22, 114, 199, 0) 94.09%)",
                        }}
                      />
                      <CardMedia
                        component="img"
                        src={badge3}
                        className={classes.imgBadge}
                      />
                    </div>
                    <div>
                      <Link
                        component={RouterLink}
                        to={`/user/${usersWithPosition[3].username}`}
                      >
                        <Typography variant="h6" className={classes.name}>
                          {`${usersWithPosition[3].username}`}
                        </Typography>
                      </Link>
                      <Typography
                        variant="subtitle1"
                        className={classes.points}
                      >
                        {`${usersWithPosition[2].reputation} Points`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 6 : ""}
                    lg={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ height: "170px" }}>
                      <CardMedia
                        component="img"
                        src={`https://secure.gravatar.com/avatar/${usersWithPosition[0].id}?s=164&d=identicon`}
                        className={classes.imgLeader}
                        style={{
                          padding: "2px",
                          borderRadius: "12px",
                          border: "4px solid",
                          borderImageSlice: 1,
                          borderImageSource:
                            "linear-gradient(180deg, #FF4755 0%, rgba(255, 71, 85, 0) 94.09%)",
                        }}
                      />
                      <CardMedia
                        component="img"
                        src={badge1}
                        className={classes.imgBadge}
                      />
                    </div>
                    <div>
                      <Link
                        component={RouterLink}
                        to={`/user/${usersWithPosition[0].username}`}
                      >
                        <Typography variant="h6" className={classes.name}>
                          {`${usersWithPosition[0].username}`}
                        </Typography>
                      </Link>
                      <Typography
                        variant="subtitle1"
                        className={classes.points}
                      >
                        {`${usersWithPosition[0].reputation} Points`}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 6 : ""}
                    lg={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ height: "170px" }}>
                      <CardMedia
                        component="img"
                        src={`https://secure.gravatar.com/avatar/${usersWithPosition[1].id}?s=164&d=identicon`}
                        className={classes.imgLeader}
                        style={{
                          padding: "2px",
                          marginTop: isMobile ? "-0.5em" : "0.5em",
                          border: "6px solid",
                          borderImageSlice: 1,
                          borderImageSource:
                            "linear-gradient(180deg, #98DB7C 0%, rgba(25, 216, 124, 0) 91.82%, rgba(110, 236, 57, 0) 91.82%)",
                          borderRadius: "12px",
                        }}
                      />
                      <CardMedia
                        component="img"
                        src={badge2}
                        className={classes.imgBadge}
                      />
                    </div>
                    <div>
                      <Link
                        component={RouterLink}
                        to={`/user/${usersWithPosition[1].username}`}
                      >
                        <Typography variant="h6" className={classes.name}>
                          {`${usersWithPosition[1].username}`}
                        </Typography>
                      </Link>
                      <Typography
                        variant="subtitle1"
                        className={classes.points}
                      >
                        {`${usersWithPosition[1].reputation} Points`}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Grid
              item
              xs={12}
              lg={6}
              className={classes.leaderboardItemContainer}
            >
              {usersWithPosition
                .filter((u) =>
                  u.username.toLowerCase().includes(filterInput.toLowerCase())
                )
                .map((u) => (
                  <Paper className={classes.leaderboardItem}>
                    <CardMedia
                      component="img"
                      src={`https://secure.gravatar.com/avatar/${u.id}?s=164&d=identicon`}
                      className={classes.img}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      marginBottom="2%"
                      width="100%"
                    >
                      <Link component={RouterLink} to={`/user/${u.username}`}>
                        <Typography variant="h6" className={classes.name}>
                          {u.username}
                        </Typography>
                      </Link>
                      <Typography
                        variant="subtitle1"
                        className={classes.points}
                      >
                        {u.reputation} pts
                      </Typography>
                    </Box>
                    <Grid
                      container
                      style={{ justifyContent: "flex-end", marginRight: "1em" }}
                    >
                      <Grid>
                        <Typography variant="h6"> {`${u.position}`}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ minWidth: "100%" }}>
          <LoadingSpinner size={80} />
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
