import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { UpvoteButton, DownvoteButton } from "./VoteButtons";
import { useAuthContext } from "../context/auth";
import PostedByUser from "./PostedByUser";
import CommentSection from "./CommentSection";
import AcceptAnswerButton from "./AcceptAnswerButton";
import DeleteDialog from "./DeleteDialog";
import AuthFormModal from "./AuthFormModal";
import { ReactComponent as AcceptedIcon } from "../svg/accepted.svg";
import { formatDateAgo, getErrorMsg } from "../utils/helperFuncs";

import {
  Typography,
  Chip,
  Button,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import { useAnsPageStyles } from "../styles/muiStyles";

const AnsDetails = ({
  quesAns,
  upvoteQuesAns,
  downvoteQuesAns,
  editQuesAns,
  deleteQuesAns,
  addComment,
  editComment,
  deleteComment,
  acceptAnswer,
  isAnswer,
  acceptedAnswer,
  quesAuthor,
}) => {
  const {
    id,
    author,
    title,
    body,
    imgRef,
    tags,
    comments,
    points,
    upvotedBy,
    downvotedBy,
    createdAt,
    updatedAt,
  } = quesAns;

  console.log(quesAns.comments);

  const classes = useAnsPageStyles();
  const { user } = useAuthContext();
  const [editAnsOpen, setEditAnsOpen] = useState(false);
  const [editedAnswerBody, setEditedAnswerBody] = useState(body);

  useEffect(() => {
    if (isAnswer) {
      setEditedAnswerBody(body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  const openEditInput = () => {
    setEditAnsOpen(true);
  };

  const closeEditInput = () => {
    setEditAnsOpen(false);
  };

  const handleAnswerEdit = (e) => {
    e.preventDefault();
    editQuesAns(editedAnswerBody, id);
    closeEditInput();
  };

  return (
    <div className={classes.quesAnsWrapper}>
      <div className={classes.quesAnsInnerWrapper}>
        <div className={classes.userNameWrapper}>
          <PostedByUser
            username={author.username}
            userId={author.id}
            createdAt={createdAt}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <span>Votes</span>
            <img src="/icons/icon-thumbs-up.png" alt="thumbs up" />
            <span>0</span>
            <img src="/icons/icon-thumbs-down.png" alt="thumbs down" />
          </div>
        </div>

        <div className={classes.quesBody}>
          {!editAnsOpen ? (
            <Typography variant="body1" style={{ wordWrap: "anywhere" }}>
              <div className={classes.titleWrapper}>
                <h3>{title}</h3>
                {tags && (
                  <div className={classes.tagsWrapper}>
                    {tags.map((t) => (
                      <Chip
                        key={t}
                        label={t}
                        variant="outlined"
                        color="primary"
                        size="small"
                        component={RouterLink}
                        to={`/tags/${t}`}
                        className={classes.tag}
                        clickable
                      />
                    ))}
                  </div>
                )}
                <p>{body}</p>
              </div>

              <div>
                {imgRef ? (
                  <img
                    src={imgRef}
                    className={classes.quesImg}
                    alt=""
                    // onClick={() => setModalOpen(true)}
                  />
                ) : (
                  ""
                )}
              </div>
            </Typography>
          ) : (
            <form className={classes.smallForm} onSubmit={handleAnswerEdit}>
              <TextField
                value={editedAnswerBody}
                required
                fullWidth
                onChange={(e) => setEditedAnswerBody(e.target.value)}
                type="text"
                placeholder="Enter at least 30 characters"
                variant="outlined"
                size="small"
                multiline
                // rows={4}
                minRows={4}
              />
              <div className={classes.submitCancelBtns}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 9 }}
                >
                  Update Answer
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => setEditAnsOpen(false)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )}
          <CommentSection
            user={user}
            comments={comments}
            addComment={addComment}
            editComment={editComment}
            deleteComment={deleteComment}
            quesAnsId={id}
          />
        </div>
      </div>
    </div>
  );
};

export default AnsDetails;
