import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { POST_QUESTION, EDIT_QUESTION } from "../graphql/mutations";
import { useStateContext } from "../context/state";
import ErrorMessage from "../components/ErrorMessage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorMsg } from "../utils/helperFuncs";
import { pinFileToIPFS } from "../utils/pinata";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  Chip,
  Input,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { FormLabel } from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

import { useAskQuesPageStyles } from "../styles/muiStyles";
import UserMenuDesktop from "./UserMenuDesktop";
import { useAuthContext } from "../context/auth";
const validationSchema = yup.object({
  title: yup
    .string()
    .required("Required")
    .min(15, "Must be at least 15 characters"),
  body: yup
    .string()
    .required("Required")
    .min(30, "Must be at least 30 characters"),
});

const AskForm = ({ setAuthType, handleModalClose }) => {
  const { user } = useAuthContext();
  const classes = useAskQuesPageStyles();
  const history = useHistory();
  const { editValues, clearEdit, notify } = useStateContext();
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState(editValues ? editValues.tags : []);
  const [errorMsg, setErrorMsg] = useState(null);
  const [url, setURL] = useState("");
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [isConfirmed, setConfirmed] = useState(false);
  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      title: editValues ? editValues.title : "",
      body: editValues ? editValues.body : "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [addQuestion, { loading: addQuesLoading }] = useMutation(
    POST_QUESTION,
    {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    }
  );

  const [updateQuestion, { loading: editQuesLoading }] = useMutation(
    EDIT_QUESTION,
    {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    }
  );

  const postQuestion = ({ title, body }) => {
    if (tags.length === 0) return setErrorMsg("Atleast one tag must be added.");

    addQuestion({
      variables: { title, body, tags, imgRef: url },
      update: (_, { data }) => {
        history.push(`/questions/${data.postQuestion.id}`);
        reset();
        notify("Question posted!");
      },
    });
  };

  const editQuestion = ({ title, body }) => {
    if (tags.length === 0) return setErrorMsg("Atleast one tag must be added.");

    updateQuestion({
      variables: { quesId: editValues.quesId, title, body, tags, imgRef: url },
      update: (_, { data }) => {
        history.push(`/questions/${data.editQuestion.id}`);
        clearEdit();
        notify("Question edited!");
      },
    });
  };

  const handleTags = (e) => {
    if (!e || (!e.target.value && e.target.value !== "")) return;
    const value = e.target.value.toLowerCase().trim();
    setTagInput(value);

    const keyCode = e.target.value
      .charAt(e.target.selectionStart - 1)
      .charCodeAt();

    if (keyCode === 32 && value.trim() !== "") {
      if (tags.includes(value))
        return setErrorMsg(
          "Duplicate tag found! You can't add the same tag twice."
        );

      if (!/^[a-zA-Z0-9-]*$/.test(value)) {
        return setErrorMsg("Only alphanumeric characters & dash are allowed.");
      }

      if (tags.length >= 5) {
        setTagInput("");
        return setErrorMsg("Max 5 tags can be added! Not more than that.");
      }

      if (value.length > 15) {
        return setErrorMsg("A single tag can't have more than 15 characters.");
      }

      setTags((prevTags) => [...prevTags, value]);
      setTagInput("");
    }
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
    if (e.target.files.length !== 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      uploadToIpfs();
    }
  };

  const uploadToIpfs = async () => {
    const imgHash = await pinFileToIPFS(file);
    setURL("https://gateway.pinata.cloud/ipfs/" + imgHash);
    notify("File uploaded to IPFS");
    notify("JSON data uploaded to IPFS");
    setConfirmed(true);
  };

  const handleClick = () => {
    const fileInput = document.getElementById("image-upload");
    fileInput.click();

    // Add an event listener to handle file after the input changes
    // fileInput.addEventListener("change", () => {
    //   uploadToIpfs();
    // });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" color="secondary">
        {editValues ? "Edit Your Question" : ""}
      </Typography>
      <form
        className={classes.quesForm}
        onSubmit={
          editValues ? handleSubmit(editQuestion) : handleSubmit(postQuestion)
        }
      >
        <div className={classes.userInfoWrapper}>
          <div className={classes.userInfo}>
            <Avatar
              alt={user.username}
              src={`https://secure.gravatar.com/avatar/${user.id}?s=164&d=identicon`}
              className={classes.postAvatar}
            />
            <Typography style={{ fontWeight: "bold" }} variant="heading1">
              {user.username}
            </Typography>
          </div>
          <CloseIcon onClick={handleModalClose} style={{ fontSize: "28px" }} />
        </div>
        <div className={classes.inputWrapper}>
          <FormLabel htmlFor="title" style={{ color: "#FFFFFF" }}>
            Title*
          </FormLabel>
          <TextField
            required
            fullWidth
            inputRef={register}
            name="title"
            placeholder="Enter title here"
            type="text"
            variant="outlined"
            size="small"
            error={"title" in errors}
            helperText={"title" in errors ? errors.title.message : ""}
            className={classes.inputField}
          />
        </div>
        <div className={classes.inputWrapper}>
          <FormLabel htmlFor="body" style={{ color: "#FFFFFF" }}>
            Description (optional)
          </FormLabel>
          <TextField
            required
            multiline
            rows={5}
            fullWidth
            inputRef={register}
            name="body"
            placeholder="Let people know more about your post"
            type="text"
            variant="outlined"
            size="small"
            error={"body" in errors}
            helperText={"body" in errors ? errors.body.message : ""}
            className={classes.inputField}
          />
        </div>

        <div className={classes.customInputWrapper}>
          <FormLabel htmlFor="body" style={{ color: "#FFFFFF" }}>
            Photo/Video (optional)
          </FormLabel>
          <Input
            id="image-upload"
            type="file"
            inputProps={{ accept: "image/*" }}
            className={classes.customInput}
            onChange={handleFile}
            placeholder=" "
          />
          <div className={classes.customLabel} onClick={handleClick}>
            <DriveFolderUploadIcon />
            Upload Photo/Video
          </div>
          <Typography variant="p" color="primary">
            {file && <p>{file.name}</p>}
          </Typography>
        </div>
        <div className={classes.inputWrapper}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            getOptionLabel={(option) => option}
            value={tags}
            inputValue={tagInput}
            onInputChange={handleTags}
            onChange={(e, value, reason) => {
              setTags(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tags"
                placeholder="Enter space button to add tags"
                onKeyDown={handleTags}
                fullWidth
                className={classes.inputField}
                size="small"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  color="primary"
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </div>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          className={classes.submitBtn}
          disabled={addQuesLoading || editQuesLoading}
        >
          {editValues ? "Update" : "Post"}
        </Button>
        <ErrorMessage
          errorMsg={errorMsg}
          clearErrorMsg={() => setErrorMsg(null)}
        />
      </form>
    </div>
  );
};

export default AskForm;
