import React, { useReducer, createContext, useContext, useEffect } from "react";
import storage from "../utils/localStorage";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import firebaseConfig from "../firebase-config";

// Initialize Firebase
initializeApp(firebaseConfig);

// Get the messaging object
const messaging = getMessaging();

// Define action types as constants
const SET_EDIT = "SET_EDIT";
const CLEAR_EDIT = "CLEAR_EDIT";
const SET_NOTIFICATION = "SET_NOTIFICATION";
const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";
const SET_FCM_TOKEN = "SET_FCM_TOKEN"; // New action type for setting FCM token

const StateContext = createContext({
  editValues: null,
  notification: null,
  darkMode: true,
  fcmToken: null, // Initialize FCM token in the context
  setEditValues: (values) => {},
  clearEdit: () => {},
  notify: (message, severity, duration) => {},
  clearNotif: () => {},
  toggleDarkMode: () => {},
  setFCMToken: (token) => {}, // Function to set FCM token in context
});

const initialState = () => ({
  editValues: null,
  notification: null,
  darkMode: false, // Update the default value if needed
  fcmToken: null, // Initialize FCM token in the state
});

const stateReducer = (state, action) => {
  switch (action.type) {
    case SET_EDIT:
      return {
        ...state,
        editValues: action.payload,
      };
    case CLEAR_EDIT:
      return {
        ...state,
        editValues: null,
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: null,
      };
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    case SET_FCM_TOKEN: // Handle setting FCM token in the state
      return {
        ...state,
        fcmToken: action.payload,
      };
    default:
      return state;
  }
};

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState());

  useEffect(() => {
    const loadedDarkMode = storage.loadDarkMode();
    if (loadedDarkMode === true) {
      dispatch({
        type: TOGGLE_DARK_MODE,
      });
    }

    // Get and set the initial FCM token
    getAndSetFCMToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEditValues = (valuesObj) => {
    dispatch({
      type: SET_EDIT,
      payload: valuesObj,
    });
  };

  const clearEdit = () => {
    dispatch({
      type: CLEAR_EDIT,
    });
  };

  let timeoutID = null;

  const notify = (message, severity = "success", duration = 5) => {
    clearTimeout(timeoutID);

    const notificationAction = {
      type: SET_NOTIFICATION,
      payload: { message, severity, duration },
    };

    dispatch(notificationAction);

    timeoutID = setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, duration * 1000);
  };

  const clearNotif = () => {
    dispatch({
      type: CLEAR_NOTIFICATION,
    });
  };

  const toggleDarkMode = () => {
    dispatch({
      type: TOGGLE_DARK_MODE,
    });

    storage.saveDarkMode(!state.darkMode);
  };

  // Function to get and set the FCM token
  const getAndSetFCMToken = async () => {
    try {
      const currentToken = await getToken(messaging);
      if (currentToken) {
        // Set the FCM token in the state
        dispatch({
          type: SET_FCM_TOKEN,
          payload: currentToken,
        });
      } else {
        console.log("No FCM token available.");
      }
    } catch (err) {
      console.error("Error getting FCM token:", err);
    }
  };

  return (
    <StateContext.Provider
      value={{
        editValues: state.editValues,
        notification: state.notification,
        darkMode: state.darkMode,
        fcmToken: state.fcmToken,
        setEditValues,
        clearEdit,
        notify,
        clearNotif,
        toggleDarkMode,
        setFCMToken: (token) => {
          // Provide a function to set FCM token in the context
          dispatch({
            type: SET_FCM_TOKEN,
            payload: token,
          });
        },
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext); // Export the useStateContext function
