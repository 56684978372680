import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useSettingsPageStyles } from "../styles/muiStyles";

const DeleteAccountModal = ({ open, onClose, onConfirm }) => {
  const classes = useSettingsPageStyles();
  return (
    <Box
      className={classes.deleteModal}
      sx={{
        p: 4,
      }}
    >
      <Typography variant="h6">Delete Account</Typography>
      <Typography variant="p">
        Are you sure you want to delete your account?
      </Typography>
      <div className={classes.modalBtnsWrapper}>
        <Button
          fullWidth
          onClick={onClose}
          style={{
            border: "2px solid",
            borderImageSource:
              "linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
            borderImageSlice: 1,
            borderRadius: "10px",
            color: "transparent",
            padding: "8px 22px",
            background:
              "-webkit-linear-gradient(91.61deg, #003638 0%, #72C974 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginRight: "1em",
          }}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          onClick={onConfirm}
          style={{
            background: "#BF0404",
            color: "#FFFFFF",
            width: "100%",
            padding: "8px 22px",
            borderRadius: "10px",
          }}
        >
          Delete
        </Button>
      </div>
    </Box>
  );
};

export default DeleteAccountModal;
