import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AuthFormModal from "./AuthFormModal";

import { Button, Menu, MenuItem, Avatar, Typography } from "@material-ui/core";
import { useMenuStyles } from "../styles/muiStyles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const UserMenuDesktop = ({ user, logoutUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useMenuStyles();

  return (
    <div>
      {user ? (
        <div style={{ display: "inline" }}>
          <Button
            className={classes.userBtn}
            component={RouterLink}
            to={`/user/${user.username}`}
          >
            <Avatar
              alt={user.username}
              src={`https://secure.gravatar.com/avatar/${user.id}?s=164&d=identicon`}
              className={classes.avatar}
            />
            <Typography color="secondary" variant="body2">
              {user.username}
            </Typography>
          </Button>
        </div>
      ) : (
        <AuthFormModal />
      )}
    </div>
  );
};

export default UserMenuDesktop;
