import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-montserrat';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apolloClient';
import { AuthProvider } from './context/auth';
import { StateProvider } from './context/state';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  argentWallet,
  braveWallet,
  imTokenWallet,
  ledgerWallet,
  omniWallet,
  trustWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { polygon , polygonMumbai} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';


const { chains, publicClient } = configureChains(
  [polygon,polygonMumbai],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY}),
    publicProvider()
  ]
);

// const connectors = connectorsForWallets([
//   {
//     groupName: "Popular",
//     wallets: [
//       injectedWallet({ chains }),
//       rainbowWallet({ chains }),
//       metaMaskWallet({ chains }),
//       coinbaseWallet({ chains }),
//       walletConnectWallet({ chains }),
//     ]
//   },
//   {
//     groupName: "Others",
//     wallets: [
//       argentWallet({ chains }),
//       braveWallet({ chains }),
//       imTokenWallet({ chains }),
//       ledgerWallet({ chains }),
//       omniWallet({ chains }),
//       trustWallet({ chains }),
//     ],
//   }
// ]);


const { connectors } = getDefaultWallets({
  appName: 'Puchooo Test',
  projectId: '37242719866d11c54aa3694cd0e275a9',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Router>
      <AuthProvider>
        <StateProvider>
          <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}>
              <App />
            </RainbowKitProvider>
          </WagmiConfig>
        </StateProvider>
      </AuthProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);