import { Switch, Route, Redirect } from "react-router-dom";
import NavMenuDesktop from "../components/NavMenuDesktop";
import RightSidePanel from "../components/RightSidePanel";
import QuesListPage from "./QuesListPage";
import RewardPage from "./RewardPage";
import AllTagsPage from "./AllTagsPage";
import Leaderboard from "./Leaderboard";
import QuestionPage from "./QuestionPage";
import SettingsPage from "./Settings";
// import AskQuestionPage from "./AskQuestionPage";
import UserPage from "./UserPage";
import MintPage from "./MintPage";
import UpdateProfilePage from "./UpdateProfilePage";
import NotFoundPage from "./NotFoundPage";
import { useAuthContext } from "../context/auth";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import {
  useQuesListStyles,
  useLeaderPageStyles,
  useRewardPageStyle,
  useMarketPageStyles,
} from "../styles/muiStyles";

import {
  Container,
  // Grid
} from "@material-ui/core";
import { Grid } from "@mui/material";
import MarketPage from "./MarketPlace";
import { useState } from "react";
import { ClassNames } from "@emotion/react";

const Routes = ({
  open,
  setOpen,
  modalOpen,
  setModalOpen,
  handleClickOpen,
}) => {
  const classes = useQuesListStyles();
  const leaderClasses = useLeaderPageStyles();
  const rewardClasses = useRewardPageStyle();
  const marketClasses = useMarketPageStyles();
  const { user } = useAuthContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("900"));
  const isXs = useMediaQuery(theme.breakpoints.down("600"));

  const [openEditModal, setOpenEditModal] = useState(false);

  const handleClickOpenEditModal = () => {
    setOpenEditModal(true);
  };

  return (
    <Container style={{ maxWidth: "100%", padding: "0" }}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Switch>
          <Route exact path="/">
            <Grid container>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={3}
                xs={12}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={9}
                sx={{
                  background: "#111618",
                  height: "90vh",
                }}
                className={classes.questListContainer}
              >
                <QuesListPage />
              </Grid>
              {!isMobile && (
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sx={{
                    borderLeft: "2px solid",
                    borderImageSlice: 1,
                    borderImageSource:
                      "linear-gradient(#003638 5%, #72C974 90%)",
                  }}
                >
                  <RightSidePanel />
                </Grid>
              )}
            </Grid>
          </Route>

          <Route exact path="/reward">
            <Grid container>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={3}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                sx={{
                  height: isMobile ? "92vh" : "90vh",
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
                className={rewardClasses.rewardWrapper}
              >
                <RewardPage />
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                style={{ display: isMobile ? "none" : "" }}
              >
                <RightSidePanel />
              </Grid>
            </Grid>
          </Route>

          <Route exact path="/leaderboard">
            <Grid container>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={3}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                sx={{
                  background: "#111618",
                  overflowY: "scroll",
                  height: "90vh",
                  "@media (min-width: 900px)": {
                    borderRight: "2px solid",
                    borderImageSlice: 1,
                    borderImageSource:
                      "linear-gradient(#003638 90%, #72C974 100%)",
                  },
                }}
                className={leaderClasses.leaderboardWrapper}
              >
                <Leaderboard />
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                style={{ display: isMobile ? "none" : "" }}
              >
                <RightSidePanel />
              </Grid>
            </Grid>
          </Route>
          <Route exact path="/mint">
            <Grid container>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={3}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                sx={{
                  height: isMobile ? "92vh" : "90vh",
                  "@media (min-width: 900px)": {
                    borderRight: "2px solid",
                    borderImageSlice: 1,
                    borderImageSource:
                      "linear-gradient(#003638 90%, #72C974 100%)",
                  },
                }}
                className={marketClasses.marketWrapper}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <MintPage />
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                style={{ display: isMobile ? "none" : "" }}
              >
                <RightSidePanel />
              </Grid>
            </Grid>
          </Route>
          <Route exact path="/marketplace">
            <Grid container>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={3}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                sx={{
                  height: isMobile ? "92vh" : "90vh",
                  "@media (min-width: 900px)": {
                    borderRight: "2px solid",
                    borderImageSlice: 1,
                    borderImageSource:
                      "linear-gradient(#003638 90%, #72C974 100%)",
                  },
                }}
                className={marketClasses.marketWrapper}
              >
                <MarketPage width={isXs ? "80%" : "100%"} />
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                style={{ display: isMobile ? "none" : "" }}
              >
                <RightSidePanel />
              </Grid>
            </Grid>
          </Route>
          <Route exact path="/settings">
            <Grid container>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={3}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                sx={{
                  height: isMobile ? "92vh" : "90vh",
                  "@media (min-width: 900px)": {
                    borderRight: "2px solid",
                    borderImageSlice: 1,
                    borderImageSource:
                      "linear-gradient(#003638 90%, #72C974 100%)",
                  },
                }}
                className={marketClasses.marketWrapper}
              >
                <SettingsPage />
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                style={{ display: isMobile ? "none" : "" }}
              >
                <RightSidePanel />
              </Grid>
            </Grid>
          </Route>
          <Route exact path="/user/:username">
            <Grid container>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={3}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                sx={{
                  height: isMobile ? "92vh" : "90vh",
                  "@media (min-width: 900px)": {
                    borderRight: "2px solid",
                    borderImageSlice: 1,
                    borderImageSource:
                      "linear-gradient(#003638 90%, #72C974 100%)",
                  },
                }}
                className={marketClasses.marketWrapper}
              >
                <UpdateProfilePage
                  openEditModal={openEditModal}
                  setOpenEditModal={setOpenEditModal}
                />
                <UserPage handleClickOpenEditModal={handleClickOpenEditModal} />
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                style={{ display: isMobile ? "none" : "" }}
              >
                <RightSidePanel />
              </Grid>
            </Grid>
          </Route>
          <Route exact path="/questions/:quesId">
            <Grid container>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                sm={3}
                sx={{
                  borderRight: "2px solid",
                  "@media (min-width: 900px)": {
                    borderRight: "2px solid",
                    borderImageSlice: 1,
                    borderImageSource:
                      "linear-gradient(#003638 90%, #72C974 100%)",
                  },
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={8}
                sm={9}
                xs={12}
                sx={{
                  height: isMobile ? "92vh" : "90vh",
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 90%, #72C974 100%)",
                }}
                className={marketClasses.marketWrapper}
              >
                <QuestionPage
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  handleClickOpen={handleClickOpen}
                />
              </Grid>
              <Grid
                item
                xl={2}
                lg={2}
                md={2}
                style={{ display: isMobile ? "none" : "" }}
              >
                <RightSidePanel />
              </Grid>
            </Grid>

            {/* <Grid container>
              <Grid
                item
                xl={1.3}
                lg={1.3}
                md={1.7}
                sm={3}
                xs={6}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 0%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={10.7}
                lg={10.7}
                md={10.3}
                sm={9}
                xs={12}
                sx={{
                  background: "#0D0D0D",
                  overflowY: "auto",
                  height: "90vh",
                  display: "flex",
                }}
              >
                <QuestionPage
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  handleClickOpen={handleClickOpen}
                />
              </Grid>
            </Grid> */}
          </Route>
          <Route exact path="/user/:username/edit">
            {user ? (
              <>
                <Grid container>
                  <Grid
                    item
                    xl={1.3}
                    lg={1.3}
                    md={1.7}
                    sm={3}
                    xs={6}
                    sx={{
                      borderRight: "2px solid",
                      borderImageSlice: 1,
                      borderImageSource:
                        "linear-gradient(#003638 5%, #72C974 90%)",
                    }}
                  >
                    <NavMenuDesktop />
                  </Grid>
                  <Grid
                    item
                    xl={10.7}
                    lg={10.7}
                    md={10.3}
                    sm={9}
                    xs={12}
                    sx={{
                      background: "#0D0D0D",
                      overflowY: "auto",
                      height: "90vh",
                    }}
                  >
                    <UpdateProfilePage
                      openEditModal={openEditModal}
                      setOpenEditModal={setOpenEditModal}
                    />
                    <UserPage
                      handleClickOpenEditModal={handleClickOpenEditModal}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/tags/:tagName">
            <Grid container>
              <Grid
                item
                xl={1.3}
                lg={1.3}
                md={1.7}
                sm={3}
                xs={6}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource: "linear-gradient(#003638 5%, #72C974 90%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={10.7}
                lg={10.7}
                md={10.3}
                sm={9}
                xs={12}
                sx={{
                  background: "#0D0D0D",
                  overflowY: "auto",
                  height: "90vh",
                }}
              >
                <Grid container>
                  <Grid item xl={9} lg={8} md={7.5}>
                    <QuesListPage tagFilterActive={true} />
                  </Grid>
                  {!isMobile && (
                    <Grid item xl={3} lg={4} md={4.5}>
                      <RightSidePanel />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Route>
          <Route exact path="/search/:query">
            <Grid container>
              <Grid
                item
                xl={1.3}
                lg={1.3}
                md={1.7}
                sm={3}
                xs={6}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource:
                    "linear-gradient(#003638 0%, #72C974 100%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={10.7}
                lg={10.7}
                md={10.3}
                sm={9}
                xs={12}
                sx={{
                  background: "#0D0D0D",
                  overflowY: "auto",
                  height: "90vh",
                }}
              >
                <QuesListPage searchFilterActive={true} />
              </Grid>
            </Grid>
          </Route>
          <Route exact path="/tags/:tagName">
            <Grid container>
              <Grid
                item
                xl={1.3}
                lg={1.3}
                md={1.7}
                sm={3}
                xs={6}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource: "linear-gradient(#003638 5%, #72C974 90%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={10.7}
                lg={10.7}
                md={10.3}
                sm={9}
                xs={12}
                sx={{
                  background: "#0D0D0D",
                  overflowY: "auto",
                  height: "90vh",
                }}
              >
                <Grid container>
                  <Grid item xl={9} lg={8} md={10}>
                    <QuesListPage searchFilterActive={true} />
                  </Grid>
                  <Grid item xl={3} lg={4} md={4}>
                    <RightSidePanel />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Route>
          <Route>
            <Grid container>
              <Grid
                item
                xl={1.3}
                lg={1.3}
                md={1.7}
                sm={3}
                xs={6}
                sx={{
                  borderRight: "2px solid",
                  borderImageSlice: 1,
                  borderImageSource: "linear-gradient(#003638 5%, #72C974 90%)",
                }}
              >
                <NavMenuDesktop />
              </Grid>
              <Grid
                item
                xl={10.7}
                lg={10.7}
                md={10.3}
                sm={9}
                xs={12}
                sx={{
                  background: "#0D0D0D",
                  overflowY: "auto",
                  height: "90vh",
                }}
              >
                <Grid container>
                  <Grid item xl={9} lg={8} md={10}>
                    <NotFoundPage />
                  </Grid>
                  <Grid item xl={3} lg={4} md={4}>
                    <RightSidePanel />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Route>
        </Switch>
      </Grid>
    </Container>
  );
};

export default Routes;
