import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER } from "../graphql/queries";
import { REWARD_USER } from "../graphql/mutations";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import { useStateContext } from "../context/state";
import { useAuthContext } from "../context/auth";
import { ethers, Contract, providers, utils } from "ethers";
import { abi, IR_CONTRACT_ADDRESS } from "../constants";
import Alert from "@material-ui/lab/Alert";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { getErrorMsg } from "../utils/helperFuncs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ConnectWallet from "../components/ConnectWallet";
import LoadingSpinner from "../components/LoadingSpinner";
import rew from "../svg/reward.svg";
import rep from "../svg/reputation.svg";

import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  Box,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import { useQuesListStyles, useRewardPageStyle } from "../styles/muiStyles";
import { useTheme } from "@material-ui/core/styles";
import { useAccount, useConnect, useDisconnect, useSignMessage } from "wagmi";

const RewardPage = () => {
  const { address, isConnected } = useAccount();
  const { clearEdit, notify } = useStateContext();
  const { user } = useAuthContext();
  const [quesData, setQuesData] = useState(null);
  const [sortBy, setSortBy] = useState("HOT");
  const [page, setPage] = useState(1);
  const classes = useRewardPageStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [username, setUsername] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [fetchedUser, setFetchedUser] = useState(null);
  const [userID, setUserID] = useState("");
  const [reward, setReward] = useState("");
  const [balance, setBalance] = useState("");
  const [transaction, setTransaction] = useState(false);
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_ALCHEMY_API_URL
  );
  const wallet = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY, provider);
  const contract = new ethers.Contract(IR_CONTRACT_ADDRESS, abi, wallet);
  const [fetchUser, { data, loading }] = useLazyQuery(GET_USER, {
    onError: (err) => {
      notify(getErrorMsg(err), "error");
    },
  });
  const [userReward, { data: rewardData, loading: rewardloading, error }] =
    useMutation(REWARD_USER, {
      onError: (err) => {
        setErrorMsg(getErrorMsg(err));
      },
    });
  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);
  useEffect(() => {
    fetchUser({ variables: { username } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    if (data) {
      setFetchedUser(data.getUser);
      setUserID(data.getUser.id);
    }
  }, [data]);

  const ClaimRewards = async () => {
    if (user) {
      const {
        id,
        account: account,
        rewards: rewards,
        username: userName,
        createdAt,
        reputation,
        totalQuestions,
        totalAnswers,
        recentQuestions,
        recentAnswers,
      } = fetchedUser;
      if (rewards === "notClaimed") {
        setTransaction(true);
        if (11 >= 11) {
          const gasLimit = 200000; // Adjust the value as needed
          const gasPrice = ethers.utils.parseUnits("10", "gwei", {
            gasLimit,
            gasPrice,
          }); // Adjust the value as needed
          await contract
            .transfer(account, ethers.utils.parseEther("1", "18"))
            .then((tx) => {
              if (tx) {
                userReward({
                  variables: {
                    id: userID.toString(),
                    input: { rewards: "Claimed" },
                  },
                  update: (_, { data }) => {
                    notify(`You've successfully claimed.`);
                    setTimeout(function () {
                      window.location.reload(true);
                    }, 3000);
                  },
                });
              }
            })
            .catch((er) => {
              notify(`Transaction failed ${er} .`, "error");
            });
        } else {
          alert("Your reputation points are not enough to unlock this!");
        }
      } else {
        alert("You already claimed!");
      }
    } else {
      alert("Please Login First!");
    }
  };
  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    // Customize the CSS of the indicator dots here
    const dotColor = isSelected ? "#A6E3A7" : "#ffffff";
    const dotStyle = {
      backgroundColor: dotColor,
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      margin: "0 5px",
      display: "inline-block",
      cursor: "pointer",
    };
    return (
      <li
        style={dotStyle}
        onClick={onClickHandler}
        key={index}
        role="button"
        aria-label={label}
        tabIndex={0}
      />
    );
  };

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs={12} sm={12}>
            <h1
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "32px",
                lineHeight: "48px",
              }}
            >
              Your awaiting rewards
            </h1>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h1
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "20px",
              }}
            >
              What you’ll get
            </h1>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h1
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "38px",
                lineHeight: "2px",
              }}
            >
              Rewards
            </h1>
          </Grid>
          <Grid item xs={12} sm={12} className="hidden sm:block">
            <Box
              p={3}
              position="relative"
              mt={2}
              style={{
                background: "rgba(255, 255, 255, 0.1)",
                width: "290px",
                height: "362px",
                borderRadius: "10px",
              }}
            >
              <Carousel
                autoPlay
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                renderIndicator={renderIndicator}
                infiniteLoop
              >
                <div>
                  <img
                    alt=""
                    src={rew}
                    style={{ width: "250px", height: "250px" }}
                  />
                  <p className={classes.onboard}>100 Reputation</p>
                  <Button
                    className={classes.buttonReward}
                    onClick={ClaimRewards}
                  >
                    Claim
                  </Button>
                </div>
                <div>
                  <img
                    alt=""
                    src={rew}
                    style={{ width: "250px", height: "250px" }}
                  />
                  <p className={classes.onboard}>500 Reputation</p>
                  <Button
                    disabled
                    className={classes.buttonReward}
                    onClick={ClaimRewards}
                  >
                    Claim
                  </Button>
                </div>
                <div>
                  <img
                    alt=""
                    src={rew}
                    style={{ width: "250px", height: "250px" }}
                  />
                  <p className={classes.onboard}>1000 Reputation</p>
                  <Button
                    disabled
                    className={classes.buttonReward}
                    onClick={ClaimRewards}
                  >
                    Claim
                  </Button>
                </div>
              </Carousel>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs={12} sm={12} style={{ marginTop: "5em" }}>
            <h1
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "20px",
              }}
            >
              How to increase
            </h1>
          </Grid>
          <Grid item xs={12} sm={12}>
            <h1
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "38px",
                lineHeight: "2px",
              }}
            >
              Reputation
            </h1>
          </Grid>
          <Grid item xs={12} sm={12} className="hidden sm:block">
            <Box
              p={3}
              justifyContent="center"
              position="relative"
              mt={2}
              style={{
                background: "rgba(255, 255, 255, 0.1)",
                width: "290px",
                height: "362px",
                borderRadius: "10px",
              }}
            >
              <Carousel
                autoPlay
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                swipeable={true}
                renderIndicator={renderIndicator}
                infiniteLoop
              >
                <div>
                  <img
                    alt=""
                    src={rep}
                    style={{ width: "250px", height: "250px" }}
                  />
                  <p className={classes.onboard}>Every 10 votes</p>
                  <Button
                    disabled
                    disableRipple
                    className={classes.buttonReputation}
                  >
                    1 Reputation
                  </Button>
                </div>
                <div>
                  <img
                    alt=""
                    src={rep}
                    style={{ width: "250px", height: "250px" }}
                  />
                  <p className={classes.onboard}>New user</p>
                  <Button
                    disabled
                    disableRipple
                    className={classes.buttonReputation}
                  >
                    5 Reputation
                  </Button>
                </div>
                <div>
                  <img
                    alt=""
                    src={rep}
                    style={{ width: "250px", height: "250px" }}
                  />
                  <p className={classes.onboard}>Every 10 questions answered</p>
                  <Button
                    disabled
                    disableRipple
                    className={classes.buttonReputation}
                  >
                    1 Reputation
                  </Button>
                </div>
              </Carousel>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RewardPage;
