import { useState, useEffect } from "react";
import { useStateContext } from "../context/state";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import NavMenuMobile from "./NavMenuMobile";
import UserMenuMobile from "./UserMenuMobile";
import UserMenuDesktop from "./UserMenuDesktop";
import SearchBar from "./SearchBar";
// import DarkModeSwitch from "./DarkModeSwitch";
import { useAuthContext } from "../context/auth";
import PuchoooLogo from "../assets/logo.png";
import { getErrorMsg } from "../utils/helperFuncs";

import {
  AppBar,
  // Toolbar,
  Typography,
  // Link,
  Button,
  // Container,
  useMediaQuery,
  IconButton,
  // Grid,
} from "@material-ui/core";
import { Grid, Dialog, DialogTitle, DialogContent } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
// import { Grid } from "@mui/material";
import {
  useAskQuesPageStyles,
  useDialogStyles,
  useNavStyles,
} from "../styles/muiStyles";
import AuthFormModal from "../components/AuthFormModal";
import { useTheme } from "@material-ui/core/styles";
// import FavoriteIcon from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
import AskForm from "./AskForm";
import { useMutation } from "@apollo/client";
import { LOGOUT_USER } from "../graphql/mutations";

const NavBar = ({ modalOpen, setModalOpen, handleClickOpen }) => {
  const { user, logoutUser } = useAuthContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const { clearEdit, fcmToken } = useStateContext();
  const [searchOpen, setSearchOpen] = useState(false);
  const client = useApolloClient();
  const location = useLocation();
  const classes = useNavStyles();
  const askClasses = useAskQuesPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile_Auth_Bar = useMediaQuery(theme.breakpoints.down("900"));
  const isMobile_Reward_Heading = useMediaQuery(theme.breakpoints.down("450"));

  const Modalclasses = useDialogStyles();

  const [userLogout, { loading }] = useMutation(LOGOUT_USER, {
    onError: (err) => {
      setErrorMsg(getErrorMsg(err));
    },
  });

  useEffect(() => {
    console.log(location.pathname);
    if (!isMobile && searchOpen) {
      setSearchOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // const handleLogout = () => {
  //   console.log("new fcmtoken",fcmToken)
  //   userLogout({
  //     variables: { id: user.id, fcmToken: fcmToken },
  //     update: (_, { data }) => {
  //       logoutUser();
  //       client.resetStore();
  //     },
  //   });
  // };

  const handleLogout = () => {
    userLogout({
      variables: { id: user.id, fcmToken: fcmToken },
      update: (_, { data }) => {
        logoutUser();
        client.resetStore();
      },
    });
  };

  const handleClick = () => {
    handleClickOpen();
    clearEdit();
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const isFeed = location.pathname === "/";

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        elevation={1}
        className={classes.appBar}
      >
        {!searchOpen && (
          <Grid container>
            {isMobile && (
              <Grid item xs={1} style={{ background: "#111618" }}>
                <NavMenuMobile />
              </Grid>
            )}

            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              sm={3}
              xs={2}
              className={classes.navbarLogoContainer}
            >
              {isMobile ? (
                <IconButton
                  className={classes.logo}
                  component={RouterLink}
                  to="/"
                >
                  <img
                    src={PuchoooLogo}
                    style={{ marginLeft: "0.5em" }}
                    width="40px"
                    alt="puchooo-logo"
                  />
                </IconButton>
              ) : (
                <Button
                  className={classes.logo}
                  component={RouterLink}
                  to="/"
                  size="large"
                >
                  <img
                    src={PuchoooLogo}
                    width="100px"
                    alt="puchooo-logo"
                    style={{ margin: "0 auto" }}
                  />
                </Button>
              )}
            </Grid>

            <Grid
              item
              xl={8}
              lg={8}
              md={8}
              sm={7}
              xs={7}
              className={classes.navbarMidContainer}
            >
              <Grid container>
                {isMobile_Auth_Bar && isFeed ? (
                  <Grid
                    container
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid item xs={6}>
                      {user ? (
                        <Button
                          variant="contained"
                          size={isMobile ? "small" : "medium"}
                          onClick={handleClick}
                          className={classes.askQuesBtn}
                        >
                          Post
                        </Button>
                      ) : (
                        <AuthFormModal buttonType="ask" />
                      )}
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="primary"
                        className={classes.searchBtn}
                        onClick={() => setSearchOpen((prevState) => !prevState)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xl={isFeed ? 10 : 12}
                    lg={isFeed ? 10 : 12}
                    md={isFeed ? 10 : 12}
                    sm={isFeed ? 10 : 12}
                    xs={isFeed ? 10 : 12}
                    className={classes.navbarMid}
                  >
                    {!isMobile && !isMobile_Auth_Bar && isFeed && <SearchBar />}

                    <div className={classes.topBar}>
                      <Typography
                        variant={isMobile ? "h6" : "h5"}
                        color="secondary"
                        style={{
                          wordWrap: "anywhere",
                          fontSize: isMobile
                            ? "15px"
                            : isMobile_Auth_Bar
                            ? "15px"
                            : "",
                          margin: isMobile ? "0 2em" : "0 1em",
                          fontWeight: isMobile ? "normal" : "bold",
                          display: isMobile_Reward_Heading ? "none" : "",
                        }}
                      >
                        {location.pathname === "/"
                          ? ""
                          : location.pathname === "/reward"
                          ? "Rewards"
                          : location.pathname === "/ask"
                          ? "Ask"
                          : location.pathname === "/leaderboard"
                          ? "Leaderboard"
                          : location.pathname === "/mint"
                          ? "Add NFT"
                          : location.pathname.startsWith("/search")
                          ? "Search Result"
                          : location.pathname === "/marketplace"
                          ? "Marketplace"
                          : location.pathname === "/settings"
                          ? "Settings"
                          : location.pathname !== "/user/:username"
                          ? "Profile"
                          : "more"}
                      </Typography>
                    </div>
                  </Grid>
                )}

                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className={classes.navbarRight}
                >
                  {isFeed && !isMobile_Auth_Bar ? (
                    user ? (
                      <Button
                        variant="contained"
                        size={isMobile ? "small" : "medium"}
                        onClick={handleClick}
                        className={classes.askQuesBtn}
                      >
                        Post
                      </Button>
                    ) : (
                      <AuthFormModal buttonType="ask" />
                    )
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className={classes.navbarRightContainer}
            >
              {isMobile || isMobile_Auth_Bar ? (
                <>
                  {location.pathname !== "/reward" ? (
                    <Grid container>
                      {/* <Grid item xs={6}>
                        <IconButton
                          color="primary"
                          className={classes.searchBtn}
                          onClick={() =>
                            setSearchOpen((prevState) => !prevState)
                          }
                        >
                          <SearchIcon />
                        </IconButton>
                      </Grid> */}
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <UserMenuMobile user={user} logoutUser={handleLogout} />
                      </Grid>
                    </Grid>
                  ) : (
                    <UserMenuMobile user={user} logoutUser={handleLogout} />
                  )}
                </>
              ) : (
                <>
                  <UserMenuDesktop user={user} logoutUser={handleLogout} />
                </>
              )}
            </Grid>
          </Grid>
        )}
        {searchOpen && isMobile_Auth_Bar && !isMobile && (
          <SearchBar
            isMobile_Auth_Bae={isMobile_Auth_Bar}
            setSearchOpen={setSearchOpen}
          />
        )}
        {searchOpen && isMobile && (
          <SearchBar isMobile={isMobile} setSearchOpen={setSearchOpen} />
        )}
      </AppBar>

      <div style={{ display: "inline" }}>
        <Dialog
          PaperProps={{
            style: {
              width: "420px",
              background: "#111618",
              boxShadow: "none",
              borderRadius: "12.5px",
            },
          }}
          open={modalOpen}
          onClose={handleModalClose}
          maxWidth="sm"
          classes={{ paper: Modalclasses.dialogWrapper }}
        >
          <DialogContent>
            <AskForm handleModalClose={handleModalClose} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default NavBar;
