import { Link as RouterLink } from "react-router-dom";
import PostedByUser from "./PostedByUser";

import {
  Paper,
  Typography,
  Chip,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import { useQuesCardStyles } from "../styles/muiStyles";
import { useState } from "react";
import { useDialogStyles } from "../styles/muiStyles";
import CloseIcon from "@mui/icons-material/Close";

const QuesCard = ({ question }) => {
  const classes = useQuesCardStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    id,
    title,
    author,
    body,
    imgRef,
    tags,
    points,
    views,
    answerCount,
    createdAt,
  } = question;

  const [open, setOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setModalOpen(true);
  };

  return (
    <>
      <Paper elevation={0} className={classes.root}>
        <div>
          <PostedByUser
            username={author.username}
            userId={author.id}
            createdAt={createdAt}
          />
        </div>
        <div className={classes.quesDetails}>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.title}
            component={RouterLink}
            to={`/questions/${id}`}
          >
            {title}
          </Typography>
          <Typography variant="body2" style={{ wordWrap: "anywhere" }}>
            {body.length > 150 ? body.slice(0, 150) + "..." : body}
          </Typography>
          <div>
            {imgRef ? (
              <img
                src={imgRef}
                className={classes.quesImg}
                alt=""
                onClick={() => setModalOpen(true)}
              />
            ) : (
              ""
            )}
          </div>
          <div className={classes.bottomWrapper}>
            <div className={classes.tagsWrapper}>
              {tags.map((t) => (
                <Chip
                  key={t}
                  label={t}
                  variant="outlined"
                  color="primary"
                  size="small"
                  component={RouterLink}
                  to={`/tags/${t}`}
                  className={classes.tag}
                  clickable
                />
              ))}
            </div>
            <div className={classes.infoWrapper}>
              <div className={classes.voteWrapper}>
                <img src="icons/icon-vote.png" alt="vote" />
                <Typography variant="body2" className={classes.mainText}>
                  {points}
                </Typography>
                <Typography className={classes.mainText}>votes</Typography>
              </div>
              <div className={classes.ansWrapper}>
                <img src="icons/icon-answer.png" alt="answer" />
                <Typography variant="body2" className={classes.mainText}>
                  {answerCount}
                </Typography>
                <Typography className={classes.mainText}>answers</Typography>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      {modalOpen && (
        <ImageModal
          imgRef={imgRef}
          onClose={handleModalClose}
          open={modalOpen}
        />
      )}
    </>
  );
};

const ImageModal = ({ imgRef, onClose, open }) => {
  const classes = useDialogStyles();

  return (
    <Dialog
      PaperProps={{
        style: {
          width: "900px",
          background: "#111618",
          boxShadow: "none",
          borderRadius: "12.5px",
        },
      }}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      classes={{ paper: classes.imgDiaglogWrapper }}
    >
      <DialogContent style={{ paddingBottom: "40px" }}>
        <div className={classes.closeIconWrapper}>
          <CloseIcon
            onClick={onClose}
            style={{ fontSize: "28px", cursor: "pointer" }}
          />
        </div>
        <img src={imgRef} alt="" className={classes.quesImgView} />
      </DialogContent>
    </Dialog>
  );
};

export default QuesCard;
